import React from 'react';
import styled from 'styled-components';

const FormButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 40rem) {
        flex-direction: column;
        min-height: 7rem;
        margin-top: 2.5rem;

        button {
            justify-content: initial;
            width: 100%;
        }
    }

    .another__candidate--container {
        display: flex;
        align-items: center;

        span {
            margin-right: 0.5rem;
            color: #7C7C7C;
            font-family: Heebo;
            letter-spacing: -0.13px;
            line-height: 24px;
            font-size: 0.7rem;
        }
    }
`;

export default FormButtonGroup;