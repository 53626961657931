import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SignatureCanvas from 'react-signature-canvas';

import Radio from '../../components/Radio';
import Input from '../../components/Input';
import InputFields from '../../components/InputFields';
import Checkbox from '../../components/Checkbox';

import {
    resetPageScroll,
} from '../../helpers';

import { SignaturePageTexts } from '../../text/text';
import { Signature_Questions } from '../../text/questions';

const HeirsDetails = ({ handleChange, handleBlur, values, setFieldValue }) => (
    <>
        <Checkbox
            name={Signature_Questions.set_legal_heirs.name}
            label={Signature_Questions.set_legal_heirs.label}
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.set_legal_heirs}
            setFieldValue={setFieldValue}
            value={values.set_legal_heirs}
        />

        {!values.set_legal_heirs && (
            <Radio
                name={Signature_Questions.numberOfHeirs.name}
                label={Signature_Questions.numberOfHeirs.label}
                onChange={handleChange}
                onBlur={handleBlur}
                options={Signature_Questions.numberOfHeirs.options}
                values={values.numberOfHeirs}
            />
        )}

        {!values.set_legal_heirs && (values.numberOfHeirs == 'מוטב 1' || values.numberOfHeirs == '2 מוטבים') && (
            <>
                <h5>מועמד ראשי</h5>

                <InputFields>
                    <Input
                        name={Signature_Questions.heir1_firstName.name}
                        label={Signature_Questions.heir1_firstName.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir1_firstName}
                    />
                    <Input
                        name={Signature_Questions.heir1_lastName.name}
                        label={Signature_Questions.heir1_lastName.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir1_lastName}
                    />
                    <Input
                        name={Signature_Questions.heir1_idNumber.name}
                        label={Signature_Questions.heir1_idNumber.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir1_idNumber}
                    />
                    <Radio
                        name={Signature_Questions.heir1_gender.name}
                        label={Signature_Questions.heir1_gender.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={Signature_Questions.heir1_gender.options}
                        values={values.heir1_gender}
                    />
                    <Input
                        name={Signature_Questions.heir1_closeness.name}
                        label={Signature_Questions.heir1_closeness.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir1_closeness}
                    />
                    <Input
                        name={Signature_Questions.heir1_percentage.name}
                        label={Signature_Questions.heir1_percentage.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir1_percentage}
                    />
                </InputFields>
            </>
        )}

        {!values.set_legal_heirs && values.numberOfHeirs == '2 מוטבים' && (
            <>
                <h5>מועמד משני</h5>
                <InputFields>
                    <Input
                        name={Signature_Questions.heir2_firstName.name}
                        label={Signature_Questions.heir2_firstName.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir2_firstName}
                    />
                    <Input
                        name={Signature_Questions.heir2_lastName.name}
                        label={Signature_Questions.heir2_lastName.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir2_lastName}
                    />
                    <Input
                        name={Signature_Questions.heir2_idNumber.name}
                        label={Signature_Questions.heir2_idNumber.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir2_idNumber}
                    />
                    <Radio
                        name={Signature_Questions.heir2_gender.name}
                        label={Signature_Questions.heir2_gender.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={Signature_Questions.heir2_gender.options}
                        values={values.heir2_gender}
                    />
                    <Input
                        name={Signature_Questions.heir2_closeness.name}
                        label={Signature_Questions.heir2_closeness.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir2_closeness}
                    />
                    <Input
                        name={Signature_Questions.heir2_percentage.name}
                        label={Signature_Questions.heir2_percentage.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values.heir2_percentage}
                    />
                </InputFields>
            </>
        )}
    </>
);


function SignaturePage({ setStep, step, handleChange, handleBlur, values, completedCandidates, currentCandidate, setAfterSignatureButtonEnabled }) {
    const sigRef = useRef(null);

    useEffect(() => {
        resetPageScroll();
    }, []);

    function handleSignatureEnd() {
        setAfterSignatureButtonEnabled(true);
        const trimmed = sigRef.current.getTrimmedCanvas();
        const dataUrl = trimmed.toDataURL();
        // const dataUrl = sigRef.current.toDataURL();
        currentCandidate.signature = dataUrl;
    }

    return (
        <>
            <h4>
                {SignaturePageTexts.isAgreePromotionalDeliveryTitle}
            </h4>
            <Radio
                name={Signature_Questions.isAgreePromotionalDelivery.name}
                label={Signature_Questions.isAgreePromotionalDelivery.label}
                onChange={handleChange}
                onBlur={handleBlur}
                options={Signature_Questions.isAgreePromotionalDelivery.options}
                values={values.isAgreePromotionalDelivery}
            />

            <Radio
                name={Signature_Questions.deliveryOptions.name}
                label={Signature_Questions.deliveryOptions.label}
                onChange={handleChange}
                onBlur={handleBlur}
                options={Signature_Questions.deliveryOptions.options}
                values={values.deliveryOptions}
            />

            {Object.keys(completedCandidates).length < 2 && currentCandidate && currentCandidate.adult && (
                <>
                    <h4>
                        {SignaturePageTexts.heirsTitle}
                    </h4>

                    <HeirsDetails
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                    />
                </>
            )}
            <div className="license__agreement">
                <div className="license__agreement--content">
                    <h4>
                        {SignaturePageTexts.mashlimShabanTitle}
                    </h4>
                    <br />
                    <ol>
                        {SignaturePageTexts.mashlimShabanText.map((paragraph, idx) => (
                            <li key={idx + paragraph.substring(0, 10)}>
                                {paragraph}
                            </li>
                        ))}
                    </ol>
                    <br />
                    <h4>
                        {SignaturePageTexts.candidatesStatementTitle}
                    </h4>
                    <br />
                    <ol>
                        {SignaturePageTexts.candidatesStatementText.map((paragraph, idx) => (
                            <li key={idx + paragraph.substring(0, 10)}>
                                {paragraph}
                            </li>
                        ))}
                    </ol>
                    <br />
                    <h4>
                        {SignaturePageTexts.releaseOnMedicalPrivilegeAgreementTitle}
                    </h4>
                    <br />
                    <p>
                        {SignaturePageTexts.releaseOnMedicalPrivilegeAgreementText}
                    </p>
                </div>
            </div>
            <h4 className="strong">
                {SignaturePageTexts.SHABANCandidateStatementTitle}
            </h4>

            <p className="strong">
                {SignaturePageTexts.SHABANCandidateStatementText}
            </p>
            <h5> {SignaturePageTexts.signature} </h5>
            <SignatureCanvas 
                ref={sigRef}
                onEnd={handleSignatureEnd}
                penColor='blue' 
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} 
            />
        </>
    )
}

export default SignaturePage;
