import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    border: 0;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.09);
    -webkit-appearance: none;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    font-family: Heebo;
    letter-spacing: -0.13px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    outline-color: #F5CB6E;
    cursor: pointer;

    background-color: ${props => props.primary ? '#80B2CD' : '#FFFFFF'};
    border: ${props => props.primary ? '1px solid transparent' : '1px solid #80B2CD'};
    color: ${props => props.primary ? '#FFFFFF' : '#80B2CD'};

    &:hover {
        background-color: #F5CB6E;
        color: #FFFFFF;
        border: 1px solid transparent;
    }

    ${props => {
        if (props.isDisabled) {
            return `
                background-color: rgba(184,211,226, 1);
                cursor: not-allowed;
                border: 0;
                color: #FFFFFF;

                &:hover {
                    background-color: rgba(184,211,226, 1);
                }
            `;
        }

        if (props.isInvisible) {
            return `
                visibility: hidden;
            `
        }
    }
    }
`;

const Button = ({ children, type = 'button', isInvisible, isDisabled, primary, ...props }) => (
    <StyledButton primary={primary} isInvisible={isInvisible} isDisabled={isDisabled} type={type} {...props}>
        {children}
    </StyledButton>
);

export default Button;
