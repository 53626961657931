const TYPES = {
    INPUT: 'Input',
    CHECKBOX: 'Checkbox',
    RADIO: 'Radio',
    RADIO_WITH_INPUT: 'RadioWithInput',
    DATE: 'DateInput',
    SELECT: 'Select',
}

export const Welcome_Page = {
    firstName: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'firstName',
        label: 'שם פרטי',
    },
    lastName: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'lastName',
        label: 'שם משפחה',
    },
    idNumber: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'idNumber',
        label: 'תעודת זהות',
    },
    gender: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'gender',
        label: 'מין',
        options: ['זכר', 'נקבה'],
    },
    birthDate: {
        questionId: '',
        type: TYPES.DATE,
        name: 'birthDate',
        label: 'תאריך לידה',
    },
    phone: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'phone',
        label: 'טלפון',
    },
    email: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'email',
        label: 'דוא"ל',
    },
    address: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'address',
        label: 'כתובת',
    },
    city: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'city',
        label: 'עיר',
    },
    isIsraeliCitizen: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'isIsraeliCitizen',
        label: 'האם את\\ה תושב קבע בישראל?',
        options: ['כן', 'לא'],
    },
    wantsToJoin: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'wantsToJoin',
        label: 'מעוניין להצטרף למסלול',
        options: ['השתלות ותרופות', 'קו למורה'],
    },
    insuranceJoinDate: {
        questionId: '',
        type: TYPES.DATE,
        name: 'insuranceJoinDate',
        label: 'תאריך הצטרפות לביטוח',
    },
    partOfMedicalClinic: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'partOfMedicalClinic',
        label: 'האם את\\ה חבר\\ה בקופת חולים בישראל?',
        options: ['כן', 'לא'],
    },    
    currentMedicalClinic: {
        questionId: '',
        type: TYPES.SELECT,
        name: 'currentMedicalClinic',
        label: 'קופת חולים',
        options: [
            { value: 'כללית', text: 'כללית' },
            { value: 'מאוחדת', text: 'מאוחדת' },
            { value: 'מכבי', text: 'מכבי' },
            { value: 'לאומית', text: 'לאומית' },
        ],
    },     
}

export const Medical_Questions_1 = {
    height: {
        questionId: '1',
        type: TYPES.INPUT,
        name: 'height',
        label: 'גובה (ס"מ)',
    },
    weight: {
        questionId: '1',
        type: TYPES.INPUT,
        name: 'weight',
        label: 'משקל (ק"ג)',
    },
    isUnder16ConsultRegardingWeight: {
        questionId: '1',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'isUnder16ConsultRegardingWeight',
        label: 'האם הייתה התייעצות עם רופא ו/או אחות ו/או דיאטנית ,לגבי משקל, השמנה או רזון?',
        labelDescription: 'נא לציין סיבות',
        options: ['כן', 'לא']
    },
    drasticWeightChangesLastFiveYears: {
        questionId: '1',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'drasticWeightChangesLastFiveYears',
        label: 'האם היו שינויים של למעלה מ 5 קג במשקל בשנה האחרונה?',
        labelDescription: 'נא לציין סיבות',
        options: ['כן', 'לא']
    },
    beenThroughInnerProcedureOrAdvicedSo: {
        questionId: '2',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'beenThroughInnerProcedureOrAdvicedSo',
        label: 'האם עברת או הומלץ לך לעבור בדיקות פולשנית בחמש השנים האחרונות (לרבות: צינתור, קולונוסקופיה, גסטרוקופיה, ציסטוסקופיה) ו/או בדיקת הדמיה (MRI,CT, אאולטרה סונד) אקו לב, מיפוי לב, מיפוי עצמות, ארגומטריה, דופלר?',
        labelDescription: 'נא לפרט סוגי הבדיקה והתוצאות. אם יש עותק של התוצאות נא לצרף',
        options: ['כן', 'לא']
    },
    hospitalizedLastFiveYears: {
        questionId: '3',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hospitalizedLastFiveYears',
        label:'האם אושפזת ב- 5 שנים האחרונות?',
        labelDescription:'נא לציין סיבת אשפוז ולצרף דו"ח אשפוז אם קיים- נא לציין פרטים ותאריך- נא לצרף דו"ח ניתוח אם קיים',
        options: ['כן', 'לא']
    },
    beenThroughAnyKindOfSurgery: {
        questionId: '4',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'beenThroughAnyKindOfSurgery',
        label: 'האם עברת ניתוח ו/או הומלץ על ניתוח בעתיד (לרבות ניתוח קוסמטי) ו/או השתלת איבר בעשר השנים האחרונות?',
        labelDescription: 'נא לציין סיבת אשפוז ולצרף דו"ח אשפוז אם קיים- נא לציין פרטים ותאריך- נא לצרף דו"ח ניתוח אם קיים',
        options: ['כן', 'לא']
    },   
    isTakingDrugsRegularly: {
        questionId: '5',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'isTakingDrugsRegularly',
        label: 'האם הנך נוטל תרופות שנרשמו לך על ידי רופא ו/או נטלת ו/או הומלץ לך על ידי רופא ליטול תרופות לתקופה העולה על שלושה שבועות בשנה האחרונה או נוטל באופן קבוע תרופות ללא מרשם?',
        labelDescription: 'נא פרט שם תרופה ומינון',
        options: ['כן', 'לא']
    },   
    hasDisabilityOrInProcess: {
        questionId: '6',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasDisabilityOrInProcess',
        label: `האם נקבעה לך נכות (מה דרגתה?) ו\או הנך בתהליך קביעת נכות ו/או הגשת תביעות נכות לגורם כלשהו בשנתיים האחרונות? (לרבות נכות ממחלה)
        האם נפצעת בתאונה בשנתיים האחרונות שהמעקב והטיפול בה עדיין לא הסתיימו?`,
        labelDescription: 'נא פרט',
        options: ['כן', 'לא']
    },   
    isASmoker: {
        questionId: '7',
        type: TYPES.RADIO,
        name: 'isASmoker',
        label: 'האם הנך מעשן/ת או עישנת בעבר?',
        options: ['כן', 'לא']
    },
    isASmokerPerDay: {
        questionId: '7.1',
        type: TYPES.INPUT,
        name: 'isASmokerPerDay',
        label: 'כמות ליום',
    },                                
    isASmokerYears: {
        questionId: '7.2',
        type: TYPES.INPUT,
        name: 'isASmokerYears',
        label: 'מספר שנים',
    },                                
    isASmokerDetails: {
        questionId: '7.3',
        type: TYPES.INPUT,
        name: 'isASmokerDetails',
        label: 'אם הפסקת לעשן - ציין מתי הייתה הפסקת עישון',
    },                                
}

export const Medical_Questions_2 = {
    hasHeartConditions: {
        questionId: '8.1',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasHeartConditions',
        label:'מחלות לב וכלי דם, יתר לחץ דם (בעשר השנים האחרונות) מומי לב מולדים?',
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },  
    hasNervousSystemConditions: {
        questionId: '8.2',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasNervousSystemConditions',
        label:'מערכת העצבים והמח לרבות: אירוע מוחי (כגון: CVA, TIA, שבץ מוחי), הפרעות קשב וריכוז? אוטיזם, תסמונת דאון?',
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },  
    hasDigestiveSystemConditions: {
        questionId: '8.3',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasDigestiveSystemConditions',
        label: 'מחלות מערכת העיקול, ושט , תרסריון, קיבה, מעיים, כבד, כיס מרה?',
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },  
    hasEndocrineSystemConditions: {
        questionId: '8.4',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasEndocrineSystemConditions',
        label: 'מחלות והפרעות במערכות ההפרשה הפנימית, לבלב- פנקריאס, בלוטות התריס (תירואיד) , חילוף חומרים, סוכרת, הזעת יתר, הפרעות הורמונליות?',
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },  
    hasEyesConditions: {
        questionId: '8.5',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasEyesConditions',
        label: 'מחלות עיניים והפרעות בראייה, מספר משקפיים מעל שמונה דיאופטריות?',
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },  
    hasEarsNoseConditions: {
        questionId: '8.6',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasEarsNoseConditions',
        label: 'מחלות והפרעות  במערכות אף, אוזן, גרון ,דום נשימה (האם נעשתה בדיקה במעבדת שינה לבדיקת הפרעות שינה / נחירות)? סטיות במחיצת האף, הפרעות שמיעה?',
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },               
    hasSkeletOrBackConditions: {
        questionId: '8.7',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasSkeletOrBackConditions',
        label: "מחלות/ כאבים במערכת השלד, עמוד שדרה והמפרקים/ראומטולוגיה, כאבי גב, דלקות פרקים, ארטריטיס, בריחת סידן, הלוקס וולגוס, שברים בשלוש השנים האחרונות?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },
    hasLungsOrCoughingConditions: {
        questionId: '8.8',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasLungsOrCoughingConditions',
        label: "מחלות והפרעות במערכת הנשימה והריאות, שיעול ממושך מעל שלושה שבועות, שיעול דמי, שחפת?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },
    hasKidneysConditions: {
        questionId: '8.9',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasKidneysConditions',
        label: "מחלות והפרעות במערכת הכליות ודרכי השתן, אי ספיקת כליות, דם או חלבון בשתן, צניחת שלפוחית השתן?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },
    hasCancerConditions: {
        questionId: '8.10',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasCancerConditions',
        label: "מחלה ממארת (סרטן) ,גידולים שפירים, לוקמיה, מיאלומה, למפומה: מלנומה, bcc?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },
    hasBloodConditions: {
        questionId: '8.11',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasBloodConditions',
        label: "מחלות מערכת הדם ,הפרעות בקרישת הדם, קרישיות יתר, תת קרישיות, apla?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },
    hasSkinConditions: {
        questionId: '8.12',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasSkinConditions',
        label: "מחלות ותופעות בעור, כתמי לידה, שומות במעקב?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },                        
    hasAutoimmuneConditions: {
        questionId: '8.13',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasAutoimmuneConditions',
        label: "מחלות במערכת החיסון(אוטואימוניות) ופגיעה במערכת החיסונית, FMF, לופוס-זאבת, איידס (לרבות נשאות נגיף האיידס HIV)?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },                        
    hasPsychConditions: {
        questionId: '8.14',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasPsychConditions',
        label: "מחלות נפש, האם אבחנת או נשלחת לאבחון בחשד למחלות נפש? האם הופנית לפסיכיאטר ב-5 השנים האחרונות?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },                        
    hasInfertilityConditions: {
        questionId: '8.15',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasInfertilityConditions',
        label: "מחלות והפרעות במערכת המין הזכרית הגדלה של בלוטת הערמונית, עלייה בערכי PSA, בעיית פריון?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },                        
    hasUnder16MaleUndescendedTestisConditions: {
        questionId: '8.15',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasUnder16MaleUndescendedTestisConditions',
        label: "לילדים עד גיל 16: אשך טמיר, הפרעות במערכת המין, מום מולד?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },                        
    hasFemaleSexConditions: {
        questionId: '8.15',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasFemaleSexConditions',
        label: "מחלות והפרעות במערכת המין הנשית הפרעות מחזור, הפלות חוזרות, הגדלה/הקטנה של השדיים, קונדילומה, ניתוחים קיסריים בעבר, בעיית פריון?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },         
    isPregrant: {
        questionId: '',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'isPregrant',
        label: "האם הינך בהריון",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },    
    hasUnder16FemalePeriodConditions: {
        questionId: '8.15',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasUnder16FemalePeriodConditions',
        label: "לנערות עד גיל 16: הפרעות מחזור , בעיות פיריון, קונדילומה?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },    
    hasHerniaConditions: {
        questionId: '8.16',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasHerniaConditions',
        label: "בקע/הרנייה (מפשעתי, בטני, טבורי, סרעפתי)?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },    
    hasCloseFamilyConditions: {
        questionId: '9',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'hasCloseFamilyConditions',
        label: "היסטוריה משפחתית: למיטב ידיעתך האם יש בין קרובי משפחתך מדרגה ראשונה (אב, אם, אחים ואחיות, בן/בת) שהינם צעירים מגיל 55 וחולים ב- מחלות לב, סרטן, סכרת, שבץ מוחי?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },
    isUnder18GrowthConditions: {
        questionId: '10',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'isUnder18GrowthConditions',
        label: "האם קיימות הפרעות התפתחות וגדילה?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },    
    isUnder18CongenitalConditions: {
        questionId: '10',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'isUnder18CongenitalConditions',
        label: "האם יש מומים מולדים?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },    
    isUnder3PrematureBirthConditions: {
        questionId: '10',
        type: TYPES.RADIO_WITH_INPUT,
        name: 'isUnder3PrematureBirthConditions',
        label: "האם נולד פג?",
        labelDescription:'נא לפרט',
        options: ['כן', 'לא']
    },    
}

export const Signature_Questions = {
    set_legal_heirs: {
        questionId: '',
        type: TYPES.CHECKBOX,
        name: 'set_legal_heirs',
        label: 'יורשים חוקיים',
    },
    numberOfHeirs: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'numberOfHeirs',
        label: 'בחר\\י כמות מוטבים',
        options: ['מוטב 1', '2 מוטבים'],
    },
    heir1_firstName: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir1_firstName',
        label: 'שם פרטי',
    },    
    heir1_lastName: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir1_lastName',
        label: 'שם משפחה',
    },    
    heir1_idNumber: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir1_idNumber',
        label: 'תעודת זהות',
    },    
    heir1_gender: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'heir1_gender',
        label: 'מין',
        options: ['זכר', 'נקבה'],
    },      
    heir1_closeness: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir1_closeness',
        label: 'קירבה',
    },    
    heir1_percentage: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir1_percentage',
        label: 'חלקים %',
    },
    heir2_firstName: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir2_firstName',
        label: 'שם פרטי',
    },    
    heir2_lastName: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir2_lastName',
        label: 'שם משפחה',
    },    
    heir2_idNumber: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir2_idNumber',
        label: 'תעודת זהות',
    },    
    heir2_gender: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'heir2_gender',
        label: 'מין',
        options: ['זכר', 'נקבה'],
    },      
    heir2_closeness: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir2_closeness',
        label: 'קירבה',
    },    
    heir2_percentage: {
        questionId: '',
        type: TYPES.INPUT,
        name: 'heir2_percentage',
        label: 'חלקים %',
    },
    isAgreePromotionalDelivery: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'isAgreePromotionalDelivery',
        label: `
            הנני מסכים כי המידע אודותיי ישמש את חברות הקבוצה ו/או מי מטעמן מעת לעת ,לצורך שיווק ודיוור ישיר מטעם חברות הקבוצה ו או חברות קשורות
            בהצעות למוצרים ושירותים ביטוחיים ,פיננסים ואחרים ,וזאת בין היתר באמצעות מערכת חיוג אוטומטי ,פקסימליה , דואר אלקטרוני והודעת מסר קצר וכל
            אמצעי תקשורת אחר .ידוע לי שבכל עת אוכל לחזור בי מהסכמה זו ולבקש הסרה מרשימת התפוצה באמצעות פנייה למוקד קשרי הלקוחות של החברה.
        `,
        options: ['מסכים', 'לא מסכים'],
    },
    deliveryOptions: {
        questionId: '',
        type: TYPES.RADIO,
        name: 'deliveryOptions',
        label: 'כיצד תרצו לקבל דיוורים שנתיים?',
        options: ['דואר אלקטרוני', 'דואר ישראל'],
    },    
}
