import React, { useState, useContext } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import store from 'store';

import './App.css';
import Header from './components/Header';
import Loading from './components/Loading';

import Candidate from './Form/Candidate';
import { AllCandidatesList } from './Form/Steps/AllCandidatesList';
import { StepsManager } from './Form/Steps/StepsManager';
import ThankYouPage from './Form/Steps/ThankYouPage';
import { exportToJsonFile, getCandidateTypes, getCandidateFiles } from './helpers';

const Steps = [
  <Candidate />,
  <AllCandidatesList />,
  <StepsManager />,
  <StepsManager />,
  <StepsManager />,
  <AllCandidatesList />,
  <ThankYouPage />
];

export const AppContext = React.createContext([{}, () => { }]);

function App() {
  const [candidates, setCandidates] = useState({});
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [step, setStep] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isAdultForm, setIsAdultForm] = useState(true);
  const [completedCandidates, setCompletedCandidates] = useState({});

  const API = 'http://tofes.shamaims.co.il/api/';

  async function sendCompletedCandidates() {
    const candidateFiles = getCandidateFiles(completedCandidates);
    const candidatesWithTypes = getCandidateTypes(completedCandidates);

    const formData = new FormData();

    formData.append('candidates', JSON.stringify(candidatesWithTypes));

    for(const key in candidateFiles) {
      const file = candidateFiles[key];
      formData.append('files[]', file);
    }

    setLoading(true);

    const result = await fetch(API, {
      method: 'post',
      body: formData,
    });

    setLoading(false);

    setStep(step + 1);
  }

  function addCandidate(candidate) {
    setCandidates({
      ...candidates,
      [candidate.idNumber]: candidate,
    });
  }

  function deleteCandidate(candidate) {
    const deleteCandidate = window.confirm('האם הנך בטוח\ה?');
    if(!deleteCandidate) return;
    
    const updatedCandidates = _.omit(candidates, candidate.idNumber);
    setCandidates(updatedCandidates);

    const updatedCompletedCandidates = _.omit(completedCandidates, candidate.idNumber);
    setCompletedCandidates(updatedCompletedCandidates);    
  }

  function editCandidate(candidate) {
    setCurrentCandidate(candidate);
    setStep(2);
  }

  function updateCandidates(candidate) {
    setCandidates({
      ...candidates,
      [candidate.idNumber]: candidate,
    })
  }

  function startFlowHandler(candidate) {
    setCurrentCandidate(candidate);
    setStep(2);
  }

  return (
    <>
      <Header />
      <AppContext.Provider value={{
        candidates,
        addCandidate,
        step,
        setStep,
        startFlowHandler,
        currentCandidate,
        setCurrentCandidate,
        isLoading,
        setLoading,
        completedCandidates,
        setCompletedCandidates,
        updateCandidates,
        deleteCandidate,
        editCandidate,
        sendCompletedCandidates,
        setIsAdultForm,
        isAdultForm,
      }}>
        <div className="App" dir="rtl">
          {Steps[step]}
          <Loading isLoading={isLoading} />
        </div>
      </AppContext.Provider>
    </>
  );
}

export default App;
