import React from 'react';
import { ScaleLoader } from 'react-spinners';
import styled from 'styled-components';

const Loading = styled.div`
    display: none;

    ${props => {
        if (props.isLoading) {
            return `
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: grid;
            position: fixed;
            justify-content: center;
            align-items: center;
            `;
        }
    }}
`;

export default ({ isLoading }) => (
    <Loading isLoading={isLoading}>
        <ScaleLoader isLoading={isLoading}/>
    </Loading>
);
