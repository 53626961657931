import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CandidateWidgets, CandidateWidget, CandidateWidgetFinished } from '../../components/CandidatesWidgets';
import { AppContext } from '../../App';

import Button from '../../components/Button';
import Divider from '../../components/Divider';

const PageContainer = styled.div`
    margin: 0 auto;
    max-width: 50vw;
    display: grid;
    grid-gap: 1rem;

    @media (max-width: 40rem) {
        max-width: 80vw;
        /* display: block; */
    }}

    .another__candidate--container {
        display: flex;
        align-items: center;

        @media (max-width: 40rem) {
            button {
                width: 100%;
            }
        }

        span {
            margin-right: 0.5rem;
            color: #7C7C7C;
            font-family: Heebo;
            letter-spacing: -0.13px;
            line-height: 24px;
            font-size: 0.7rem;
        }
    }

    span.next__button--info{
        justify-self: flex-end;
        margin-right: 0.5rem;
        color: #7C7C7C;
        font-family: Heebo;
        letter-spacing: -0.13px;
        line-height: 24px;
        font-size: 0.7rem;
    }

    h1 {
        font-size: 40px;
        color: #80B2CD;
        letter-spacing: -0.33px;
        font-family: Heebo;
        line-height: 59px;
        font-weight: 100;
        margin: 0;
        margin-top: 1rem;
    }

    h2 {
        font-family: Heebo;
        font-weight: bold;
        color: #80B2CD;
        letter-spacing: -0.21px;
        line-height: 37px;
        font-size: 25px;
    }

    p {
        font-family: Heebo;
        color: #7C7C7C;
        letter-spacing: -0.13px;
        font-size: 16px;
        margin: 0;
    }

    .step1__submit {
        @media (max-width: 40rem) {
            /* margin: 0.5rem; */
            width: 100%;
        }
    }

`;

export const AllCandidatesList = () => {
    const { setLoading, candidates, setStep, step, startFlowHandler, completedCandidates, deleteCandidate, sendCompletedCandidates, setIsAdultForm, editCandidate } = useContext(AppContext);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <PageContainer>
            <h1>הצטרפות לביטוח</h1>
            <p>
                להלן שמות המועמדים לביטוח
            </p>
            <CandidateWidgets>
                <>
                    {!!Object.keys(completedCandidates).length && Object.keys(completedCandidates).map((candidate, idx) =>
                        <CandidateWidgetFinished
                            key={candidate}
                            startFlowHandler={startFlowHandler}
                            candidate={completedCandidates[candidate]}
                            deleteCandidate={deleteCandidate}
                            editCandidate={editCandidate}
                        />)
                    }
                    {!!Object.keys(candidates).length && Object.keys(candidates).map((candidate, idx) => {
                        if (!completedCandidates[candidate]) {
                            return (
                                <CandidateWidget
                                    key={candidate}
                                    startFlowHandler={startFlowHandler}
                                    candidate={candidates[candidate]}
                                    deleteCandidate={deleteCandidate}
                                    editCandidate={editCandidate}
                                />
                            );
                        }
                    })
                    }
                </>
            </CandidateWidgets>
            <Divider />
            {/* <div className="another__candidate--container">
                <Button
                    isDisabled={Object.keys(candidates).length >= 6}
                    onClick={() => setStep(0)}>
                    הוספת מועמד נוסף
                </Button>
            </div> */}

            <div className="another__candidate--container">
                <Button
                    onClick={() => {
                        setStep(0);
                        setIsAdultForm(true);
                    }}
                >
                    הוספת בגיר
                </Button>
                <Button 
                    style={{ marginRight: '0.5rem', }}
                    onClick={() => {
                        setStep(0);
                        setIsAdultForm(false);
                    }}
                >
                    הוספת קטין
                </Button>
            </div>            
            <Button
                primary
                className="step1__submit"
                onClick={sendCompletedCandidates}
                disabled={(Object.keys(candidates).length != Object.keys(completedCandidates).length) || !Object.keys(completedCandidates).length}
                isDisabled={(Object.keys(candidates).length != Object.keys(completedCandidates).length) || !Object.keys(completedCandidates).length}
                style={{ justifySelf: 'flex-end' }}
            >
                שלח טפסים
                </Button>
            {Object.keys(candidates).length != Object.keys(completedCandidates).length && (
                <span className="next__button--info">נא למלא את פרטי כל המועמדים</span>
            )}
        </PageContainer>
    );
}
