
import * as Yup from 'yup';

import {
    calculateAge,
} from '../helpers';


export const errorMessages = {
    height: 'ערך לא תקין',
    weight: 'ערך לא תקין',

    drasticWeightChangesLastFiveYears: 'שדה חובה',
    drasticWeightChangesLastFiveYearsDetails: 'אנא הוסיפו פרטים נוספים',

    beenThroughInnerProcedureOrAdvicedSo: 'שדה חובה',
    beenThroughInnerProcedureOrAdvicedSoDetails: 'אנא הוסיפו פרטים נוספים',

    isUnder16ConsultRegardingWeight: 'שדה חובה',
    isUnder16ConsultRegardingWeightDetails: 'אנא הוסיפו פרטים נוספים',

    hospitalizedLastFiveYears: 'שדה חובה',
    hospitalizedLastFiveYearsDetails: 'אנא הוסיפו פרטים נוספים',

    beenThroughAnyKindOfSurgery: 'שדה חובה',

    isTakingDrugsRegularly: 'שדה חובה',
    isTakingDrugsRegularlyDetails: 'אנא הוסיפו פרטים נוספים',
    isHandicapped: 'שדה חובה',
    hadAccident: 'שדה חובה',

    isASmoker: 'שדה חובה',
    isASmokerPerDay: 'אנא הוסיפו פרטים נוספים',
    isASmokerYears: 'אנא הוסיפו פרטים נוספים',
}

export const requiredMessages = {
    height: 'נא לציין את הגובה של המועמד',
    weight: 'נא לציין את המשקל של המועמד',
    drasticWeightChangesLastFiveYears: 'שדה חובה',
    drasticWeightChangesLastFiveYearsDetails: 'נא לפרט',

    isUnder16ConsultRegardingWeight: 'שדה חובה',
    isUnder16ConsultRegardingWeightDetails: 'נא לפרט',

    beenThroughInnerProcedureOrAdvicedSo: 'שדה חובה',
    beenThroughInnerProcedureOrAdvicedSoDetails: 'נא לפרט',

    hospitalizedLastFiveYears: 'שדה חובה',
    hospitalizedLastFiveYearsDetails: 'נא לפרט',

    beenThroughAnyKindOfSurgery: 'שדה חובה',
    beenThroughAnyKindOfSurgeryDetails: 'נא לפרט',

    isTakingDrugsRegularly: 'שדה חובה',
    isTakingDrugsRegularlyDetails: 'נא לפרט',
    isHandicapped: 'שדה חובה',
    hadAccident: 'שדה חובה',

    hasDisabilityOrInProcess: 'שדה חובה',
    hasDisabilityOrInProcessDetails: 'נא לפרט',

    isASmoker: 'שדה חובה',
    isASmokerPerDay: 'נא לפרט',
    isASmokerYears: 'נא לפרט',

    hasHeartConditions: 'שדה חובה',
    hasHeartConditionsDetails: '',

    hasNervousSystemConditions: 'שדה חובה',
    hasNervousSystemConditionsDetails: 'נא לפרט',

    hasDigestiveSystemConditions: 'שדה חובה',
    hasDigestiveSystemConditionsDetails: 'נא לפרט',

    hasEndocrineSystemConditions: 'שדה חובה',
    hasEndocrineSystemConditionsDetails: 'נא לפרט',

    hasEyesConditions: 'שדה חובה',
    hasEyesConditionsDetails: 'נא לפרט',

    hasEarsNoseConditions: 'שדה חובה',
    hasEarsNoseConditionsDetails: 'נא לפרט',

    hasSkeletOrBackConditions: 'שדה חובה',
    hasSkeletOrBackConditionsDetails: 'נא לפרט',

    hasLungsOrCoughingConditions: 'שדה חובה',
    hasLungsOrCoughingConditionsDetails: 'נא לפרט',

    hasKidneysConditions: 'שדה חובה',
    hasKidneysConditionsDetails: 'נא לפרט',

    hasCancerConditions: 'שדה חובה',
    hasCancerConditionsDetails: 'נא לפרט',

    hasBloodConditions: 'שדה חובה',
    hasBloodConditionsDetails: 'נא לפרט',

    hasSkinConditions: 'שדה חובה',
    hasSkinConditionsDetails: 'נא לפרט',

    hasAutoimmuneConditions: 'שדה חובה',
    hasAutoimmuneConditionsDetails: 'נא לפרט',

    hasPsychConditions: 'שדה חובה',
    hasPsychConditionsDetails: 'נא לפרט',

    hasInfertilityConditions: 'שדה חובה',
    hasInfertilityConditionsDetails: 'נא לפרט',

    hasUnder16MaleUndescendedTestisConditions: 'שדה חובה',
    hasUnder16MaleUndescendedTestisConditionsDetails: 'נא לפרט',

    hasFemaleSexConditions: 'שדה חובה',
    hasFemaleSexConditionsDetails: 'נא לפרט',

    isPregrant: 'שדה חובה',
    isPregrantDetails: 'נא לציין את מספר השבוע',

    hasUnder16FemalePeriodConditions: 'שדה חובה',
    hasUnder16FemalePeriodConditionsDetails: 'נא לפרט',

    hasHerniaConditions: 'שדה חובה',
    hasHerniaConditionsDetails: 'נא לפרט',

    hasCloseFamilyConditions: 'שדה חובה',
    hasCloseFamilyConditionsDetails: 'נא לפרט',

    isUnder18CongenitalConditions: 'שדה חובה',
    isUnder18CongenitalConditionsDetails: 'נא לפרט',


    isUnder18GrowthConditions: 'שדה חובה',
    isUnder18GrowthConditionsDetails: 'נא לפרט',


    isUnder3PrematureBirthConditions: 'שדה חובה',
    isUnder3PrematureBirthConditionsDetails: 'נא לפרט',

    'isAgreePromotionalDelivery': 'שדה חובה',
    'deliveryOptions': 'שדה חובה',
    'set_legal_heirs': 'שדה חובה',
    'heir1_firstName': 'שדה חובה',
    'heir1_lastName': 'שדה חובה',
    'heir1_idNumber': 'שדה חובה',
    'heir1_gender': 'שדה חובה',
    'heir1_closeness': 'שדה חובה',
    'heir1_percentage': 'שדה חובה',
    'heir2_firstName': 'שדה חובה',
    'heir2_lastName': 'שדה חובה',
    'heir2_idNumber': 'שדה חובה',
    'heir2_gender': 'שדה חובה',
    'heir2_closeness': 'שדה חובה',
    'heir2_percentage': 'שדה חובה',
}

export const step2Values = [
    'height',
    'weight',
    'drasticWeightChangesLastFiveYears',
    'drasticWeightChangesLastFiveYearsDetails',
    'isUnder16ConsultRegardingWeight',
    'isUnder16ConsultRegardingWeightDetails',    
    'beenThroughInnerProcedureOrAdvicedSo',
    'beenThroughInnerProcedureOrAdvicedSoDetails',
    'hospitalizedLastFiveYears',
    'hospitalizedLastFiveYearsDetails',
    'beenThroughAnyKindOfSurgery',
    'beenThroughAnyKindOfSurgeryDetails',
    'hasDisabilityOrInProcess',
    'hasDisabilityOrInProcessDetails',
    'isTakingDrugsRegularly',
    'isTakingDrugsRegularlyDetails',
    'isHandicapped',
    'isHandicappedDetails',
    'hadAccident',
    'hadAccidentDetails',
    'isASmoker',
    'isASmokerPerDay',
    'isASmokerYears',
    'isASmokerDetails',
];

export const step3Values = [
    'hasHeartConditions',
    'hasHeartConditionsDetails',

    'hasNervousSystemConditions',
    'hasNervousSystemConditionsDetails',

    'hasDigestiveSystemConditions',
    'hasDigestiveSystemConditionsDetails',

    'hasEndocrineSystemConditions',
    'hasEndocrineSystemConditionsDetails',

    'hasEyesConditions',
    'hasEyesConditionsDetails',

    'hasEarsNoseConditions',
    'hasEarsNoseConditionsDetails',

    'hasSkeletOrBackConditions',
    'hasSkeletOrBackConditionsDetails',

    'hasLungsOrCoughingConditions',
    'hasLungsOrCoughingConditionsDetails',

    'hasKidneysConditions',
    'hasKidneysConditionsDetails',

    'hasCancerConditions',
    'hasCancerConditionsDetails',

    'hasBloodConditions',
    'hasBloodConditionsDetails',

    'hasSkinConditions',
    'hasSkinConditionsDetails',

    'hasAutoimmuneConditions',
    'hasAutoimmuneConditionsDetails',

    'hasPsychConditions',
    'hasPsychConditionsDetails',

    'hasInfertilityConditions',
    'hasInfertilityConditionsDetails',

    'hasUnder16MaleUndescendedTestisConditions',
    'hasUnder16MaleUndescendedTestisConditionsDetails',

    'hasFemaleSexConditions',
    'hasFemaleSexConditionsDetails',

    'isPregrant',
    'isPregrantDetails',

    'hasUnder16FemalePeriodConditions',
    'hasUnder16FemalePeriodConditionsDetails',

    'hasHerniaConditions',
    'hasHerniaConditionsDetails',

    'hasCloseFamilyConditions',
    'hasCloseFamilyConditionsDetails',

    'isUnder18CongenitalConditions',
    'isUnder18CongenitalConditionsDetails',


    'isUnder18GrowthConditions',
    'isUnder18GrowthConditionsDetails',


    'isUnder3PrematureBirthConditions',
    'isUnder3PrematureBirthConditionsDetails',
];

export const step4Values = [
    'isAgreePromotionalDelivery',
    'deliveryOptions',
    'set_legal_heirs',
    'heir1_firstName',
    'heir1_lastName',
    'heir1_idNumber',
    'heir1_gender',
    'heir1_closeness',
    'heir1_percentage',
    'heir2_firstName',
    'heir2_lastName',
    'heir2_idNumber',
    'heir2_gender',
    'heir2_closeness',
    'heir2_percentage',
];

export const initialValues = {
    height: '',
    weight: '',
    drasticWeightChangesLastFiveYears: '',
    drasticWeightChangesLastFiveYearsDetails: '',
    isUnder16ConsultRegardingWeight: '',
    isUnder16ConsultRegardingWeightDetails: '',    
    beenThroughInnerProcedureOrAdvicedSo: '',
    beenThroughInnerProcedureOrAdvicedSoDetails: '',
    hospitalizedLastFiveYears: '',
    hospitalizedLastFiveYearsDetails: '',
    beenThroughAnyKindOfSurgery: '',
    hasDisabilityOrInProcess: '',
    hasDisabilityOrInProcessDetails: '',    
    isTakingDrugsRegularly: '',
    isTakingDrugsRegularlyDetails: '',
    isHandicapped: '',
    isHandicappedDetails: '',
    hadAccident: '',
    hadAccidentDetails: '',
    isASmoker: '',
    isASmokerPerDay: '',
    isASmokerYears: '',
    isASmokerDetails: '',

    hasHeartConditions: '',
    hasHeartConditionsDetails: '',

    hasNervousSystemConditions: '',
    hasNervousSystemConditionsDetails: '',

    hasDigestiveSystemConditions: '',
    hasDigestiveSystemConditionsDetails: '',

    hasEndocrineSystemConditions: '',
    hasEndocrineSystemConditionsDetails: '',

    hasEyesConditions: '',
    hasEyesConditionsDetails: '',

    hasEarsNoseConditions: '',
    hasEarsNoseConditionsDetails: '',

    hasSkeletOrBackConditions: '',
    hasSkeletOrBackConditionsDetails: '',

    hasLungsOrCoughingConditions: '',
    hasLungsOrCoughingConditionsDetails: '',

    hasKidneysConditions: '',
    hasKidneysConditionsDetails: '',

    hasCancerConditions: '',
    hasCancerConditionsDetails: '',

    hasBloodConditions: '',
    hasBloodConditionsDetails: '',

    hasSkinConditions: '',
    hasSkinConditionsDetails: '',

    hasAutoimmuneConditions: '',
    hasAutoimmuneConditionsDetails: '',

    hasPsychConditions: '',
    hasPsychConditionsDetails: '',

    hasInfertilityConditions: '',
    hasInfertilityConditionsDetails: '',

    hasUnder16MaleUndescendedTestisConditions: '',
    hasUnder16MaleUndescendedTestisConditionsDetails: '',

    hasFemaleSexConditions: '',
    hasFemaleSexConditionsDetails: '',

    isPregrant: '',
    isPregrantDetails: '',

    hasUnder16FemalePeriodConditions: '',
    hasUnder16FemalePeriodConditionsDetails: '',

    hasHerniaConditions: '',
    hasHerniaConditionsDetails: '',

    hasCloseFamilyConditions: '',
    hasCloseFamilyConditionsDetails: '',

    isUnder18CongenitalConditions: '',
    isUnder18CongenitalConditionsDetails: '',


    isUnder18GrowthConditions: '',
    isUnder18GrowthConditionsDetails: '',


    isUnder3PrematureBirthConditions: '',
    isUnder3PrematureBirthConditionsDetails: '',

    'isAgreePromotionalDelivery': '',
    'deliveryOptions': '',
    'set_legal_heirs': true,
    'heir1_firstName': '',
    'heir1_lastName': '',
    'heir1_idNumber': '',
    'heir1_gender': '',
    'heir1_closeness': '',
    'heir1_percentage': '',
    'heir2_firstName': '',
    'heir2_lastName': '',
    'heir2_idNumber': '',
    'heir2_gender': '',
    'heir2_closeness': '',
    'heir2_percentage': '',


    'drasticWeightChangesLastFiveYears_file': '',
    'isUnder16ConsultRegardingWeight_file': '',
    'beenThroughInnerProcedureOrAdvicedSo_file': '',
    'hospitalizedLastFiveYears_file': '',
    'isTakingDrugsRegularly_file': '',
    'isHandicapped_file': '',
    'hadAccident_file': '',
    'hasHeartConditions_file': '',
    'hasDigestiveSystemConditions_file': '',
    'hasEndocrineSystemConditions_file': '',
    'hasEyesConditions_file': '',
    'hasEarsNoseConditions_file': '',
    'hasSkeletOrBackConditions_file': '',
    'hasLungsOrCoughingConditions_file': '',
    'hasKidneysConditions_file': '',
    'hasBloodConditions_file': '',
    'hasSkinConditions_file': '',
    'hasAutoimmuneConditions_file': '',
    'hasPsychConditions_file': '',
    'hasInfertilityConditions_file': '',
    'hasUnder16MaleUndescendedTestisConditions_file': '',
    'hasFemaleSexConditions_file': '',
    'isPregrant_file': '',
    'hasUnder16FemalePeriodConditions_file': '',
    'hasHerniaConditions_file': '',
    'hasCloseFamilyConditions_file': '',
    'isUnder18GrowthConditions_file': '',
    'isUnder3PrematureBirthConditions_file': '',
}

export const insuranceHealthQuestionValidation = Yup.object().shape({
    height: Yup.string()
        .min(2, errorMessages['height'])
        .required(requiredMessages['height']),
    weight: Yup.string()
        .min(2, errorMessages['weight'])
        .required(requiredMessages['weight']),

    isUnder16ConsultRegardingWeightDetails: Yup.string()
    .when('isUnder16ConsultRegardingWeight', {
        is: 'כן',
        then: Yup.string()
        .when('birthDate', {
            is: val => calculateAge(val) < 16,
            then: Yup.string()
                .required(requiredMessages['isUnder16ConsultRegardingWeightDetails']),
        }),
    }),

    drasticWeightChangesLastFiveYearsDetails: Yup.string()
    .when('isUnder16ConsultRegardingWeight', {
        is: 'כן',
        then: Yup.string()
        .when('birthDate', {
            is: val => calculateAge(val) >= 16,
            then: Yup.string()
                .required(requiredMessages['drasticWeightChangesLastFiveYearsDetails']),
        }),
    }),    

    beenThroughInnerProcedureOrAdvicedSo: Yup.string()
        .required(requiredMessages['beenThroughInnerProcedureOrAdvicedSo']),
    beenThroughInnerProcedureOrAdvicedSoDetails: Yup.string()
        .when('beenThroughInnerProcedureOrAdvicedSo', {
            is: 'כן',
            then: Yup.string()
                .required(requiredMessages['beenThroughInnerProcedureOrAdvicedSoDetails']),
        }),


    hospitalizedLastFiveYears: Yup.string()
        .required(requiredMessages['hospitalizedLastFiveYears']),
    hospitalizedLastFiveYearsDetails: Yup.string()
        .when('hospitalizedLastFiveYears', {
            is: 'כן',
            then: Yup.string()
                .required(requiredMessages['hospitalizedLastFiveYearsDetails']),
        }),


    beenThroughAnyKindOfSurgery: Yup.string()
        .required(requiredMessages['beenThroughAnyKindOfSurgery']),

    hasDisabilityOrInProcess: Yup.string()
        .required(requiredMessages['hasDisabilityOrInProcess']),

    hasDisabilityOrInProcessDetails: Yup.string()
        .when('hasDisabilityOrInProcess', {
            is: 'כן',
            then: Yup.string()
                .required(requiredMessages['hasDisabilityOrInProcessDetails']),
        }),

    beenThroughAnyKindOfSurgeryDetails: Yup.string()
        .when('beenThroughAnyKindOfSurgery', {
            is: 'כן',
            then: Yup.string()
                .required(requiredMessages['beenThroughAnyKindOfSurgeryDetails']),
        }),

    isTakingDrugsRegularly: Yup.string()
        .required(requiredMessages['isTakingDrugsRegularly']),
    isTakingDrugsRegularlyDetails: Yup.string()
        .when('isTakingDrugsRegularly', {
            is: 'כן',
            then: Yup.string()
                .required(requiredMessages['isTakingDrugsRegularlyDetails']),
        }),

    isASmoker: Yup.string()
        .required(requiredMessages['isASmoker']),
    isASmokerPerDay: Yup.string()
        .when('isASmoker', {
            is: 'כן',
            then: Yup.string()
                .required(requiredMessages['isASmokerPerDay']),
        }),
    isASmokerYears: Yup.string()
        .when('isASmoker', {
            is: 'כן',
            then: Yup.string()
                .required(requiredMessages['isASmokerYears']),
        }),

    hasHeartConditions: Yup.string()
        .required(requiredMessages['hasHeartConditions']),

    hasNervousSystemConditions: Yup.string()
        .required(requiredMessages['hasNervousSystemConditions']),

    hasDigestiveSystemConditions: Yup.string()
        .required(requiredMessages['hasDigestiveSystemConditions']),

    hasEndocrineSystemConditions: Yup.string()
        .required(requiredMessages['hasEndocrineSystemConditions']),

    hasEyesConditions: Yup.string()
        .required(requiredMessages['hasEyesConditions']),

    hasEarsNoseConditions: Yup.string()
        .required(requiredMessages['hasEarsNoseConditions']),

    hasSkeletOrBackConditions: Yup.string()
        .required(requiredMessages['hasSkeletOrBackConditions']),

    hasLungsOrCoughingConditions: Yup.string()
        .required(requiredMessages['hasLungsOrCoughingConditions']),

    hasKidneysConditions: Yup.string()
        .required(requiredMessages['hasKidneysConditions']),

    hasCancerConditions: Yup.string()
        .required(requiredMessages['hasCancerConditions']),

    hasBloodConditions: Yup.string()
        .required(requiredMessages['hasBloodConditions']),

    hasSkinConditions: Yup.string()
        .required(requiredMessages['hasSkinConditions']),

    hasAutoimmuneConditions: Yup.string()
        .required(requiredMessages['hasAutoimmuneConditions']),

    hasPsychConditions: Yup.string()
        .required(requiredMessages['hasPsychConditions']),

    hasInfertilityConditions: Yup.string()
        .when('gender', {
            is: 'זכר',
            then: Yup.string()
                .required(requiredMessages['hasInfertilityConditions']),
        }),

    hasUnder16MaleUndescendedTestisConditions: Yup.string()
        .when('gender', {
            is: 'זכר',
            then: Yup.string()
                .when('birthDate', {
                    is: val => calculateAge(val) < 16,
                    then: Yup.string()
                        .required(requiredMessages['hasUnder16MaleUndescendedTestisConditions']),
                }),
        }),

    hasFemaleSexConditions: Yup.string()
        .when('gender', {
            is: 'נקבה',
            then: Yup.string()
                .required(requiredMessages['hasFemaleSexConditions']),
        }),

    isPregrant: Yup.string()
        .when('gender', {
            is: 'נקבה',
            then: Yup.string()
                .required(requiredMessages['isPregrant']),
        }),


    isPregrantDetails: Yup.string()
        .when('isPregrant', {
            is: 'כן',
            then: Yup.string()
                .required(requiredMessages['isPregrantDetails']),
        }),

    hasUnder16FemalePeriodConditions: Yup.string()
        .when('gender', {
            is: 'נקבה',
            then: Yup.string()
                .when('birthDate', {
                    is: val => calculateAge(val) < 16,
                    then: Yup.string()
                        .required(requiredMessages['hasUnder16FemalePeriodConditions']),
                }),
        }),

    hasHerniaConditions: Yup.string()
        .required(requiredMessages['hasHerniaConditions']),

    hasCloseFamilyConditions: Yup.string()
        .required(requiredMessages['hasCloseFamilyConditions']),

    isUnder18CongenitalConditions: Yup.string()
        .when('birthDate', {
            is: val => calculateAge(val) < 18,
            then: Yup.string()
                .required(requiredMessages['isUnder18CongenitalConditions']),
        }),

    isUnder18GrowthConditions: Yup.string()
        .when('birthDate', {
            is: val => calculateAge(val) < 18,
            then: Yup.string()
                .required(requiredMessages['isUnder18GrowthConditions']),
        }),

    isUnder3PrematureBirthConditions: Yup.string()
        .when('birthDate', {
            is: val => calculateAge(val) < 3,
            then: Yup.string()
                .required(requiredMessages['isUnder3PrematureBirthConditions']),
        }),

    hasHeartConditionsDetails: Yup.string()
        .when('hasHeartConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasHeartConditionsDetails']),
        }),

    hasNervousSystemConditionsDetails: Yup.string()
        .when('hasNervousSystemConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasNervousSystemConditionsDetails']),
        }),

    hasDigestiveSystemConditionsDetails: Yup.string()
        .when('hasDigestiveSystemConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasDigestiveSystemConditionsDetails']),
        }),

    hasEndocrineSystemConditionsDetails: Yup.string()
        .when('hasEndocrineSystemConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasEndocrineSystemConditionsDetails']),
        }),

    hasEyesConditionsDetails: Yup.string()
        .when('hasEyesConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasEyesConditionsDetails']),
        }),

    hasEarsNoseConditionsDetails: Yup.string()
        .when('hasEarsNoseConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasEarsNoseConditionsDetails']),
        }),

    hasSkeletOrBackConditionsDetails: Yup.string()
        .when('hasSkeletOrBackConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasSkeletOrBackConditionsDetails']),
        }),

    hasLungsOrCoughingConditionsDetails: Yup.string()
        .when('hasLungsOrCoughingConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasLungsOrCoughingConditionsDetails']),
        }),

    hasKidneysConditionsDetails: Yup.string()
        .when('hasKidneysConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasKidneysConditionsDetails']),
        }),

    hasCancerConditionsDetails: Yup.string()
        .when('hasCancerConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasCancerConditionsDetails']),
        }),

    hasBloodConditionsDetails: Yup.string()
        .when('hasBloodConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasBloodConditionsDetails']),
        }),

    hasSkinConditionsDetails: Yup.string()
        .when('hasSkinConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasSkinConditionsDetails']),
        }),

    hasAutoimmuneConditionsDetails: Yup.string()
        .when('hasAutoimmuneConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasAutoimmuneConditionsDetails']),
        }),

    hasPsychConditionsDetails: Yup.string()
        .when('hasPsychConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasPsychConditionsDetails']),        
        }),
        
    hasInfertilityConditionsDetails: Yup.string()
        .when('hasInfertilityConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasInfertilityConditionsDetails']),        
        }),
        
    hasUnder16MaleUndescendedTestisConditionsDetails: Yup.string()
        .when('hasUnder16MaleUndescendedTestisConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasUnder16MaleUndescendedTestisConditionsDetails']),        
        }),
        
    hasFemaleSexConditionsDetails: Yup.string()
        .when('hasFemaleSexConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasFemaleSexConditionsDetails']),        
        }),
        
            hasUnder16FemalePeriodConditionsDetails: Yup.string()
        .when('hasUnder16FemalePeriodConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasUnder16FemalePeriodConditionsDetails']),        
        }),
        
    hasHerniaConditionsDetails: Yup.string()
        .when('hasHerniaConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasHerniaConditionsDetails']),        
        }),
        
    hasCloseFamilyConditionsDetails: Yup.string()
        .when('hasCloseFamilyConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['hasCloseFamilyConditionsDetails']),        
        }),
        
    isUnder18CongenitalConditionsDetails: Yup.string()
        .when('isUnder18CongenitalConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['isUnder18CongenitalConditionsDetails']),        
        }),
        
    isUnder18GrowthConditionsDetails: Yup.string()
        .when('isUnder18GrowthConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['isUnder18GrowthConditionsDetails']),        
        }),
        
    isUnder3PrematureBirthConditionsDetails: Yup.string()
        .when('isUnder3PrematureBirthConditions', {
            is: 'כן',
            then: Yup.string()
            .required(requiredMessages['isUnder3PrematureBirthConditionsDetails']),        
        }),        

    isAgreePromotionalDelivery: Yup.string()
        .when('email', {
            is: val => Boolean(val),
            then: Yup.string()
                .required(requiredMessages['isAgreePromotionalDelivery'])
        }),

    deliveryOptions: Yup.string()
        .required(requiredMessages['deliveryOptions']),

    set_legal_heirs: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .required(requiredMessages['set_legal_heirs']),
        }),

    heir1_firstName: Yup.boolean()
        .when('adult', {
            is: true,
            then: Yup.boolean()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .required(requiredMessages['heir1_firstName']),
                })
        }),

    heir1_lastName: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .required(requiredMessages['heir1_lastName']),
                })
        }),

    heir1_idNumber: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .required(requiredMessages['heir1_idNumber']),
                })
        }),

    heir1_gender: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .required(requiredMessages['heir1_gender']),
                })
        }),

    heir1_closeness: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .required(requiredMessages['heir1_closeness']),
                })
        }),

    heir1_percentage: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .required(requiredMessages['heir1_percentage']),
                })
        }),

    heir2_firstName: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .when('numberOfHeirs', {
                            is: '2 מוטבים',
                            then: Yup.string()
                                .required(requiredMessages['heir2_firstName']),
                        })
                })
        }),

    heir2_lastName: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .when('numberOfHeirs', {
                            is: '2 מוטבים',
                            then: Yup.string()
                                .required(requiredMessages['heir2_lastName']),
                        })
                })
        }),

    heir2_idNumber: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .when('numberOfHeirs', {
                            is: '2 מוטבים',
                            then: Yup.string()
                                .required(requiredMessages['heir2_idNumber']),
                        })
                })
        }),

    heir2_gender: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .when('numberOfHeirs', {
                            is: '2 מוטבים',
                            then: Yup.string()
                                .required(requiredMessages['heir2_gender']),
                        })
                })
        }),

    heir2_closeness: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .when('numberOfHeirs', {
                            is: '2 מוטבים',
                            then: Yup.string()
                                .required(requiredMessages['heir2_closeness']),
                        })
                })
        }),

    heir2_percentage: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('set_legal_heirs', {
                    is: false,
                    then: Yup.string()
                        .when('numberOfHeirs', {
                            is: '2 מוטבים',
                            then: Yup.string()
                                .required(requiredMessages['heir2_percentage']),
                        })
                })
        }),
});


export const candidateErrorMessages = {
    firstName: 'שם פרטי - קצר מידי',
    lastName: 'שם משפחה - קצר מידי',
    idNumber: 'מספר תעודת זהות - קצר מידי',
    birthDate: 'תאריך לא תקין',
    insuranceJoinDate: 'תאריך לא תקין',
    gender: '',
    phone: 'מספר הטלפון אינו תקין',
    email: 'כתובת הדוא"ל אינה תקינה',
    address: 'כתובת - קצרה מידי',
    city: 'שם עיר - קצר מידי',
    isIsraeliCitizen: '',
    wantsToJoin: '',
    partOfMedicalClinic: '',
}

export const candidateRequiredMessages = {
    firstName: 'מה השם הפרטי של המועמד?',
    lastName: 'מה שם המשפחה של המועמד?',
    idNumber: 'מה מספר ת. הזהות של המועמד?',
    birthDate: 'מה תאריך הלידה של המועמד?',
    insuranceJoinDate: 'באיזה תאריך תהיו מעוניינים להצטרף?',
    gender: 'נא לבחור את מין המועמד',
    phone: 'מה מספר הטלפון של המועמד?',
    email: 'מהי כתובת הדוא"ל  של המועמד?',
    address: 'מהי הכתובת של המועמד?',
    city: 'באיזו עיר גר המועמד?',
    isIsraeliCitizen: '',
    wantsToJoin: '',
    partOfMedicalClinic: 'נא לציין האם את\\ה חבר\\ה בקופת חולים',
    currentMedicalClinic: 'נא לציין קופת חולים',
}

export const candidateInitialValues = {
    adult: true,
    firstName: '',
    lastName: '',
    idNumber: '',
    birthDate: '',
    insuranceJoinDate: '',
    gender: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    isIsraeliCitizen: '',
    wantsToJoin: '',
    partOfMedicalClinic: '',
    currentMedicalClinic: '',
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const birthDateRegExp = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

export const candidateFormValidation = Yup.object().shape({
    firstName: Yup.string()
        .min(2, candidateErrorMessages['firstName'])
        .required(candidateRequiredMessages['firstName']),
    lastName: Yup.string()
        .min(2, candidateErrorMessages['lastName'])
        .required(candidateRequiredMessages['lastName']),
    email: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .email(candidateErrorMessages['email'])
                .required(candidateRequiredMessages['email']),
        }),
    idNumber: Yup.string()
        .min(9, candidateErrorMessages['idNumber'])
        .required(candidateRequiredMessages['idNumber']),
    // birthDate: Yup.date()
    //     .required(candidateRequiredMessages['birthDate']),
    birthDate: Yup.string()
        .matches(birthDateRegExp, candidateErrorMessages['birthDate'])
        .required(candidateRequiredMessages['birthDate']),
    phone: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .matches(phoneRegExp, candidateErrorMessages['phone'])
                .required(candidateRequiredMessages['phone']),
        }),
    address: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .min(2, candidateErrorMessages['address'])
                .required(candidateRequiredMessages['address']),
        }),
    city: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .min(2, candidateErrorMessages['city'])
                .required(candidateRequiredMessages['city']),
        }),
    gender: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .required(candidateRequiredMessages['gender']),
        }),
    partOfMedicalClinic: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .required(candidateRequiredMessages['partOfMedicalClinic']),
        }),

    currentMedicalClinic: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .when('partOfMedicalClinic', {
                    is: 'כן',
                    then: Yup.string()
                        .required(candidateRequiredMessages['currentMedicalClinic']),
                }),
        }),

    insuranceJoinDate: Yup.string()
        .when('adult', {
            is: true,
            then: Yup.string()
                .required(candidateRequiredMessages['insuranceJoinDate']),
        }),
});
