import { flatten as _flatten, keyBy as _keyBy } from 'lodash';
import { useState } from 'react';

export function useLocalStorage() {

}

export function scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export function calculateAge(date) { // birthday is a date
    var birthday = new Date(date);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function resetDateInputs() {
    console.log('resetDateInputs');
    console.log(document.querySelectorAll('.date__input'));
    document.querySelectorAll('.date__input').forEach(dateInput => dateInput.value = '');
}

export function resetSelects() {
    document.querySelectorAll('select').forEach(select => select.value = '');
}

export function resetCheckboxes() {
    document.querySelectorAll(':checked').forEach(radio => radio.checked = false);
}

export function resetPageScroll() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export function exportToJsonFile(jsonData) {
    let dataStr = JSON.stringify(jsonData);
    let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

    let exportFileDefaultName = 'data.json';

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
}

const candidateTypes = {
    primaryCandidate: 'primaryCandidate',
    secondaryCandidate: 'secondaryCandidate',
    childAbove25: 'childAbove25',
    childUnder25: 'childUnder25',
}

export function getCandidateTypes(candidates) {
    let childAbove25Count = 1;
    let childUnder25Count = 1;
    let secondaryCandidateIsSet = false;

    const candidatesWithTypes = Object.keys(candidates).map((key, idx) => {
        const candidate = candidates[key];

        if (idx == 0 && candidate.adult) {
            candidate.candidateType = candidateTypes.primaryCandidate;
        } else if (candidate.adult && !secondaryCandidateIsSet) {
            candidate.candidateType = candidateTypes.secondaryCandidate;
            secondaryCandidateIsSet = true;
        } else if (calculateAge(candidate.birthDate) < 25 || !candidate.adult) {
            candidate.candidateType = `${candidateTypes.childUnder25}_${childUnder25Count}`;
            childUnder25Count += 1;
        } else if (calculateAge(candidate.birthDate) >= 25) {
            candidate.candidateType = `${candidateTypes.childAbove25}_${childAbove25Count}`;
            childAbove25Count += 1;
        }
        return candidate;
    });

    return _keyBy(candidatesWithTypes, candidate => candidate.idNumber);
}

export function getCandidateFiles(candidates) {
    const FILE = '_file';
    const candidateFiles = Object.keys(candidates).map((candidateIdNumber, idx) => {
        const candidate = candidates[candidateIdNumber];
        const candidateFiles = {};

        for(const key in candidate) {
            if(key.toLowerCase().includes(FILE) && candidate[key]) {
                const { idNumber } = candidate;
                candidateFiles[`${key}_${idNumber}`] = candidate[key];
            }
        }

        return candidateFiles;
    });

    const reduced = candidateFiles.reduce((a,c) => {
        a = {
            ...a,
            ...c,
        }

        return a;
    }, {});

    return reduced;
}