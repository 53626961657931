export const SignaturePageTexts = {
    isAgreePromotionalDeliveryTitle: `
        הסכמה לקבלת פנייה בקווי תקשורת
    `,

    heirsTitle: `
        מינוי מוטבים למקרה מוות
    `,

    mashlimShabanTitle: `
        משלים שב"ן – גילוי נאות
    `,
    mashlimShabanText: [
        `
        הכיסוי בגין ניתוחים על פי הפוליסה מהווה ביטוח משלים, אשר על פיו ישולמו תגמולי ביטוח בגין ניתוח המכוסה בפוליסה, שהם מעל ומעבר
        להשתתפות השב"ן (שירותי בריאות נוספים בקופת החולים). כלומר, המבטח ישלם את ההפרש שבין ההוצאות בפועל של ניתוח המכוסה על פי
        הפוליסה לבין ההוצאות המגיעות מהשב"ן וזאת עד לתקרה הקבועה בפוליסה.
        `,

        `
        למימוש כיסוי בגין ניתוח, על המבוטח לפנות לקופת חולים למימוש זכויותיו על פי השב"ן וכן לפנות למבטח למימוש זכויותיו על פי הפוליסה
        `,

        `
        דמי הביטוח בגין תוכנית זו נמוכים מדמי הביטוח בגין תוכנית בעלת כיסוי ביטוחי מ"השקל הראשון" (תוכנית אשר תגמולי הביטוח משולמים בה ללא
        תלות בזכויות המגיעות בשב"ן).
        `,

        `
        הביטוח כולל ברות ביטוח, יצוין כי בעת סיום החברות בתוכנית השב"ן זכאי המבוטח לפנות למבטח ולבקש לעבור לכיסוי ביטוחי מ"השקל הראשון" תוך
        60 ימים ממועד הודעת קופת החולים על ביטול תוכנית השב"ן או מהמועד בו ייכנס הביטול לתוקף, לפי המאוחר מבין שני המועדים.
        `,
    ],

    candidatesStatementTitle: `
        הצהרת המועמדים לביטוח
    `,
    candidatesStatementText: [
        `
        הנני מצהיר/ה כי המידע הכלול בהצהרה זו וכן מידע שיימסר על ידי בעתיד לחברת הביטוח ו/או מי מטעמה נמסר מרצוני החופשי ובהסכמה מלאה ולא
        מתוך חובה חוקית כלשהי.
        `,

        `
        ידוע לי ואני מסכים/ה כי המידע האמור אודותיי וכן כל מידע שייצבר אודותיי בעתיד בגין הקשור לפוליסה יישמר במאגרי המידע של חברת הביטוח כנהוג
        אצלה ואו חברות מהקבוצה ו/או אצל גורם אחר מטעמה, אשר תעשה במידע שימוש לצרכיה לרבות, ניהול, הפקת פוליסת ביטוח, טיפול בכל העניינים
        הנלווים מהפקת הפוליסה והקשורים אליה וטיפול בתביעות הביטוח לרבות עיבוד המידע ,צרכים אקטואריים ועסקיים ו/או שירותים נלווים אחרים ובכפוף
        לכל דין. “הקבוצה“ הפניקס אחזקות בע“מ, חברות הבת וכן כל החברות והגופים שהפניקס אחזקות בע“מ ו/או הפניקס ביטוח בע“מ בעלי שליטה בהם
        של %50 או יותר. ידוע לי כי המידע יימסר לצדדים שלישיים לשם אחסון ועיבוד המידע, לצורך טיפול בתביעות ומשלוח דיוורים בקשר עם תוכנית
        הביטוח והכל בכפוף להוראות חוק הגנת הפרטיות התשמ"א – 1981.
        `,

        `
        הנני מאשר כי המידע הכלול בטופס זה וכל מידע נוסף שיימסר בעתיד על ידי לחברה, נמסר בהסכמתי וכי לא חלה עלי חובה חוקית למסור את המידע
        ידוע לי שהמידע ישמר במאגרי המידע של החברה ואו חברות מהקבוצה ו/או אצל גורם אחר מטעמה וישמש לצורך ניהול, תפעול וטיפול בכל ענייני
        הביטוח לרבות עיבוד המידע, צרכים אקטואריים ועסקיים ו/או שירותים נלווים אחרים ובכפוף לכל דין. ”הקבוצה“- הגדרת הקבוצה בטופס זה תכלול את
        הפניקס אחזקות בע“מ, חברות הבת וכן כל החברות והגופים שהפניקס אחזקות בע“מ ו/או הפניקס ביטוח בע“מ בעלי שליטה בהם של %50 או יותר.
        `,

        `
        ידוע לי כי פרטי ההתקשרות (דוא"ל ו/או טלפון נייד) שצינתי בטופס זה יעודכנו עבור כלל תכניות הביטוח ככל שישנם ברשותי בקבוצה.
        `,

        `
        אני מצהיר/ה כי הוצע לי לרכוש כיסוי משלים שב"ן + ברות ביטוח.
        `,

        `
        אני החתום/ה מטה מבקש/ת להצטרף ו/או לצרף את בני משפחתי לביטוח הבריאות הקבוצתי של המעסיק.
        `,

        `
        ידוע לי כי תשלום הפרמיה עבורי ועבור בני משפחתי תגבה באמצעות אמצעי גבייה אישי.
        `,
    ],

    releaseOnMedicalPrivilegeAgreementTitle: `
        ויתור על סודיות רפואית
    `,
    releaseOnMedicalPrivilegeAgreementText: `
        אני הח"מ המבוטח,נותן\ת בזה רשות לקופ"ח ו\או לעובדיה הרפואיים האחרים ו\או למוסדותיה הרפואיים ו\או לסניפיה ו\או לשלטונות צה"ל ו\או למשטרה ,וכן
        לכל הרופאים ,המוסדות הרפואיים ובתי חולים האחרים ולמוסד לביטוח לאומי ו\או לכל מוסד ולכל חברות הביטוח וגורם אחר למסור ל"הפניקס חברה לביטוח
        בע"מ "ו/או הפניקס ניהול קרנות פנסיה וגמולים בע"מ ,את כל הפרטים הנדרשים לצורך צירוף לביטוח ויישוב תביעות בלבד ובצורה שתדרש ע"י הפניקס" ,
        על מצב בריאותי ו\או על כל מחלה שחלתי בה בעבר ו\או שהנני חולה בה כעת ו\או שאחלה בה בעתיד והנני משחרר אתכם וכל רופא מרופאיכם ו\או כל עובד
        רפואי אחר ו\או מוסד ממוסדותיכם ו\או כל סניף מסניפכם מחובת שמירה על סודיות רפואית בכל הנוגע למצב בריאותי, מחלותיי כנ"ל ומוותר על סודיות זו
        כלפי" הפניקס "ולא תהינה לי אליכם ,כל טענה או תביעה מסוג כלשהו בקשר לנ"ל .כתב ויתור זה מחייב אותי את עזבוני ובאי כוחי החוקיים וכל מי שיבוא
        במקומי .החברה תהיה רשאית להעביר את המידע לגופים משפטיים הנכללים ב"הפניקס "ולסוכן הביטוח המטפל.
    `,

    signature: 'חתימה',
    
    SHABANCandidateStatementTitle: `
        הצהרת המועמדים לביטוח למשלים שב"ן
    `,
    SHABANCandidateStatementText: `
        אני מצהיר/ה בזאת כי הנני חבר/ה במסגרת השב"ן של קופ"ח בה אני חבר וכי ידוע שלי שחברותי זו הינה תנאי הכרחי ומהותי לרכישת הכיסוי
        "משלים שב"ן".
        הצהרה זו חלה גם על ילדים הכלולים בפוליסה ואני חותם/ת על הצהרה בשמם כאפוטרופוס/ית טבעי/ת.
    `,
}

export const insuaranceCoverageText = {
description: `
השתלות וטיפולים מיוחדים בארץ ובחו"ל.
תרופות שאינן כלולות בסל הבריאות והרחבה לתרופות המותאמות אישית.
ניתוחים ומחליפי ניתוח בישראל, ניתוחים בחו"ל,
שירותים אמבולטורים כגון:
    `,
list: [
    'התייעצות עם רופא מומחה',
    'בדיקות רפואיות אבחנתיות',
    'טיפולי פיזיוטרפיה',
    'טיפולים פסיכולוגיים',
    'טיפולי רפואה משלימה',
    'בדיקות הריון',
    'טיפולי פריון',
]
}