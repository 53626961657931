import React from 'react';
import styled from 'styled-components';

const Divider = styled.hr`
    border: 0;
    height: 1px;
    background: #c7c7c7;
    margin: 0;
`;

export default Divider;
