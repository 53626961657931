import React from 'react';
import styled from 'styled-components';

export const ErrorMessageContainer = styled.div`
    min-height: 1.5rem;
`;

const ErrorMsg = styled.div`
    font-size: 0.9rem;
    letter-spacing: -0.13px;
    color: #F81717;
    font-family: Heebo !important;
`;

export default ErrorMsg;
