import React from 'react';
import styled from 'styled-components';

const InputFields = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;

    @media (max-width: 40rem) {
        /* display: block; */
        grid-template-columns: 1fr;
    }}
`;


export default InputFields;
