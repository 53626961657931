import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import ErrorMsg from './ErrorMsg';

const InputHolder = styled.div`
    display: flex;
    align-items: center;

    label {
        color: #7C7C7C;
        font-family: Heebo;
        letter-spacing: -0.13px;
        line-height: 24px;
        font-size: 16px;    
    }
`;

const Checkbox = ({ name, checked, type="checkbox", onChange, onBlur, label, value }) => (
    <InputHolder isChecked={checked}>
        <input
            id={name}
            name={name}
            type={type}
            checked={checked}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
        />
        <label htmlFor={name}>{label}</label>
    </InputHolder>
);

export default Checkbox;
