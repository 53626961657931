import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';

import {
    resetCheckboxes,
    resetSelects,
    resetDateInputs
} from '../helpers';

import {
    candidateInitialValues,
    candidateFormValidation,
} from './validation';

import { CandidateWidgets, CandidateWidget } from '../components/CandidatesWidgets';
import Input from '../components/Input';
import Button from '../components/Button';
import Radio from '../components/Radio';
import Divider from '../components/Divider';
import Select from '../components/Select';
import DateInput from '../components/DateInput';
import FormButtonGroup from '../components/FormButtonGroup';
import InputFields from '../components/InputFields';

import { insuaranceCoverageText } from '../text/text';
import { Welcome_Page } from '../text/questions';

import { AppContext } from '../App';

const CandidateContainer = styled.div`
    margin: 0 auto;
    max-width: 60vw;

    @media (max-width: 40rem) {
        max-width: 90vw;
    }

    h1 {
        font-size: 40px;
        color: #80B2CD;
        letter-spacing: -0.33px;
        font-family: Heebo;
        line-height: 59px;
        font-weight: 100;

        @media (max-width: 40rem) {
            font-size: 35px;
        }
    }

    h2 {
        font-family: Heebo;
        font-weight: bold;
        color: #80B2CD;
        letter-spacing: -0.21px;
        line-height: 37px;
        font-size: 25px;
    }

    h3 {
        font-family: Heebo;
        letter-spacing: -0.21px;
        line-height: 37px;
        font-size: 20px;
        color: #7C7C7C;
    }

    h4 {
        font-family: Heebo;
        letter-spacing: -0.21px;
        line-height: 37px;
        color: #7C7C7C;        
        margin: 0;
    }

    .insurance__coverage {
        ol, p {
            margin: 0;
            padding: 0;
            font-family: Heebo;
            color: #7C7C7C;     
            font-size: 1rem;
        }

        p {
            white-space: pre;
            line-height: 1.1rem;
        }

        ol {
            list-style-position: inside;
        }
    }

    form {
        display: grid;
        grid-template-rows: 1fr auto;
        grid-gap: 2rem;

        @media (max-width: 40rem) {
            display: block;
        }
    }

    button[type="submit"] {
        @media (max-width: 40rem) {
            width: 100%;
            margin-bottom: 0.5rem;
        }
    }

    .inputfields__candidate {
        @media (max-width: 40rem) {
            display: block;
        }
    }

`;

const Candidate = () => {
    const { setLoading, candidates, addCandidate, step, setStep, isLoading, setIsAdultForm, isAdultForm } = useContext(AppContext);

    useEffect(() => {
        resetSelects();
    });

    const intialValues = {
        ...candidateInitialValues,
        adult: isAdultForm,
    };

    function handleSubmit(values, functions) {
        const { setSubmitting, setErrors, setStatus, resetForm } = functions;
        console.log(functions);
        setLoading(true);
        resetForm();
        resetCheckboxes();
        addCandidate(values);
        setSubmitting(true);

        setTimeout(() => {
            setStep(step + 1);
        }, 800);
    }

    return (
        <CandidateContainer>
            {Boolean(Object.keys(candidates).length) && (
                <span
                    style={{
                        fontSize: '1rem',
                        textDecoration: 'underline',
                        color: '#F5CB6E',
                        cursor: 'pointer',
                    }}
                        onClick={() => {
                            console.log(candidates);
                            setStep(step + 1);
                        }}
                    >
                        &lt;&lt; חזרה
                </span>            
            )}            
            <h1>פרטי המועמד לביטוח</h1>
            <h2>מועמד לביטוח {Object.keys(candidates).length + 1}</h2>
            <h3>הנך ממלא טופס הצטרפות לביטוח בריאות קבוצתי</h3>          
            <h4>כיסויי הביטוח</h4>
            <div className="insurance__coverage">
                <p>
                    {insuaranceCoverageText.description}
                </p>
                <br />
                <ol>
                    {insuaranceCoverageText.list.map((listItem, idx) => <li key={idx + listItem.substring(0, 10)}>{listItem}</li>)}
                </ol>
            </div>
            <br />

            <Formik
                initialValues={intialValues}
                validationSchema={candidateFormValidation}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    validateForm,
                    resetForm,
                    setFieldTouched,
                    setStatus,
                    setFieldValue,
                    setValues,
                }) => (
                        <form onSubmit={handleSubmit}>
                            <InputFields className="inputfields__candidate">
                                <input type="hidden" value={values.adult} name="adult" />
                                <Input
                                    questionId={Welcome_Page.firstName.questionId}
                                    name={Welcome_Page.firstName.name}
                                    label={Welcome_Page.firstName.label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                />
                                <Input
                                    questionId={Welcome_Page.lastName.questionId}
                                    name={Welcome_Page.lastName.name}
                                    label={Welcome_Page.lastName.label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                />
                                <Input
                                    questionId={Welcome_Page.idNumber.questionId}                                
                                    name={Welcome_Page.idNumber.name}
                                    label={Welcome_Page.idNumber.label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.idNumber}
                                />
                                <Radio
                                    questionId={Welcome_Page.gender.questionId}                                
                                    name={Welcome_Page.gender.name}
                                    label={Welcome_Page.gender.label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    options={Welcome_Page.gender.options}
                                    values={values.gender}
                                />
                                <DateInput
                                    questionId={Welcome_Page.birthDate.questionId}                                
                                    name={Welcome_Page.birthDate.name}
                                    label={Welcome_Page.birthDate.label}
                                    onBlur={handleBlur}
                                    value={values.birthDate}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    isLoading={isLoading}
                                />

                                {isAdultForm && (
                                    <>
                                        <Input
                                            questionId={Welcome_Page.phone.questionId}                                
                                            name={Welcome_Page.phone.name}
                                            label={Welcome_Page.phone.label}
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Input
                                            type="email"
                                            questionId={Welcome_Page.email.questionId}                                
                                            name={Welcome_Page.email.name}
                                            label={Welcome_Page.email.label}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        <Input
                                            questionId={Welcome_Page.address.questionId}                                
                                            name={Welcome_Page.address.name}
                                            label={Welcome_Page.address.label}
                                            value={values.address}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Input
                                            questionId={Welcome_Page.city.questionId}                                
                                            name={Welcome_Page.city.name}
                                            label={Welcome_Page.city.label}
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Radio
                                            questionId={Welcome_Page.isIsraeliCitizen.questionId}                                
                                            name={Welcome_Page.isIsraeliCitizen.name}
                                            label={Welcome_Page.isIsraeliCitizen.label}
                                            options={Welcome_Page.isIsraeliCitizen.options}
                                            values={values.isIsraeliCitizen}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Radio
                                            questionId={Welcome_Page.wantsToJoin.questionId}                                
                                            name={Welcome_Page.wantsToJoin.name}
                                            label={Welcome_Page.wantsToJoin.label}
                                            options={Welcome_Page.wantsToJoin.options}
                                            values={values.wantsToJoin}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <DateInput
                                            questionId={Welcome_Page.insuranceJoinDate.questionId}                                
                                            name={Welcome_Page.insuranceJoinDate.name}
                                            label={Welcome_Page.insuranceJoinDate.label}
                                            value={values.insuranceJoinDate}
                                            setFieldTouched={setFieldTouched}
                                            setFieldValue={setFieldValue}
                                            isLoading={isLoading}
                                        />
                                        <Radio
                                            questionId={Welcome_Page.partOfMedicalClinic.questionId}                                
                                            name={Welcome_Page.partOfMedicalClinic.name}
                                            label={Welcome_Page.partOfMedicalClinic.label}
                                            options={Welcome_Page.partOfMedicalClinic.options}
                                            values={values.partOfMedicalClinic}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </>
                                )}

                                {values.partOfMedicalClinic == 'כן' && (
                                    <Select
                                        questionId={Welcome_Page.currentMedicalClinic.questionId}                                
                                        name={Welcome_Page.currentMedicalClinic.name}
                                        label={Welcome_Page.currentMedicalClinic.label}
                                        options={Welcome_Page.currentMedicalClinic.options}
                                        values={values.currentMedicalClinic}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                    />
                                )}
                            </InputFields>
                            <Divider />
                            <FormButtonGroup>
                                <div className="another__candidate--container">
                                    <Button onClick={() => validateForm().then(x => {
                                        const errorFields = Object.keys(x);
                                        if (errorFields.length) {
                                            errorFields.forEach(key => {
                                                setFieldTouched(key);
                                            });
                                        } else {
                                            setLoading(true);
                                            resetForm();
                                            resetCheckboxes();
                                            setTimeout(() => {
                                                addCandidate(values);
                                                setValues({ adult: true });
                                                setIsAdultForm(true);
                                                setLoading(false);
                                            }, 1200);
                                        }
                                    })} disabled={Object.keys(candidates).length >= 6} isDisabled={Object.keys(candidates).length >= 6}>
                                        הוספת בגיר
                                    </Button>
                                    <Button style={{ marginRight: '0.5rem', }} onClick={() => validateForm().then(x => {
                                        const errorFields = Object.keys(x);
                                        if (errorFields.length) {
                                            errorFields.forEach(key => {
                                                setFieldTouched(key);
                                            });
                                        } else {
                                            setLoading(true);
                                            resetForm();
                                            resetCheckboxes();
                                            setTimeout(() => {
                                                addCandidate(values);
                                                setValues({ adult: false });
                                                setIsAdultForm(false);
                                                setLoading(false);
                                            }, 1200);
                                        }
                                    })} disabled={Object.keys(candidates).length >= 6} isDisabled={Object.keys(candidates).length >= 6}>
                                        הוספת קטין
                                    </Button>
                                </div>
                                <Button primary type='submit'>
                                    להמשיך לשאלון
                                </Button>
                            </FormButtonGroup>
                            <CandidateWidgets>
                                {!!Object.keys(candidates).length && Object.keys(candidates).map((candidate, idx) => <CandidateWidget key={candidate} candidate={candidates[candidate]} />)}
                            </CandidateWidgets>
                        </form>
                    )}
            </Formik>
        </CandidateContainer>
    );
}

export default Candidate;
