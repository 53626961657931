import React from 'react';
import styled from 'styled-components';

import Radio from './Radio';
import InputWithUpload from './InputWithUpload';

function RadioWithInput({
    questionId,
    name,
    label,
    labelDescription,
    values,
    options,
    onBlur,
    onChange,
    currentCandidate,
    condition,
    setFieldValue,
    formikValues
}) {
    return (
        <>
        <Radio
            name={name}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            values={values}
        />            
        {
            values == 'כן' && (
                <InputWithUpload
                    questionId={questionId}
                    uploadFileName={`${name}_file`}
                    type="text"
                    name={`${name}Details`}
                    label={labelDescription}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values[`${name}Details`]}
                    width={'100%'}
                    setFieldValue={setFieldValue}
                    formikValues={formikValues}
                />
            )
        } 
        </>           
    )
}

export default RadioWithInput;
