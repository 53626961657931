import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import ErrorMsg, { ErrorMessageContainer } from './ErrorMsg';

import arrow_down from '../assets/arrow_down.png';

const Options = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0.3rem;
    max-height: ${props => props.isOpen ? '300px' : '0'};
    height: auto;
    overflow: hidden;
    transition: all .3s ease-out;
    background: #FFF;

    li {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        color: #7C7C7C;
        letter-spacing: -0.13px;
        padding-right: 0.5rem;
        font-family: Heebo;
        cursor: pointer;
        margin-top: 4px;
        border: 0.5px solid transparent;
    }

    li:hover {
        border-color: #ddd6;
        border-radius: 12px;
        width: 100%;
    }    
`

const Toggle = styled.div`
    background-color: #FFF;
    height: 40px;
    color: #7C7C7C !important;
    padding-right: 0.5rem;
    margin: 0;
    font-family: Heebo;
    border: 1px solid transparent;
    font-size: 1rem;
    letter-spacing: -0.13px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    margin-bottom: ${props => props.isOpen ? '5px' : '0'};

    @media (max-width: 40rem) {
        height: 55px;
        align-items: center;
        padding-top: 10px;
    }

    &::after {
        content: '';
        background-image: url("${arrow_down}");
        background-repeat: no-repeat;
        background-size: 100%;
        width: 1rem;
        height: 1rem;
        margin-left: 0.5rem;
        justify-self: flex-end;
        transform: ${props => props.isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'};
        transform-origin: center top;
        transition: all .3s ease-out;
        position: relative;
        top: ${props => props.isOpen ? '5px' : '0'};

        @media (max-width: 40rem) {
            top: ${props => props.isOpen ? '10px' : '0'};
        }
    }

    &:focus {
        border-color: 1px solid #F5CB6E;
    }
`

const InputHolder = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 4rem;
    justify-content: space-around;

    grid-row: 6;
    grid-column: 1;

    width: 250px;
    margin: 0.5rem;

    @media (max-width: 40rem) {
        width: initial;
        grid-row: initial;
        grid-column: initial;
    }
    
    label {
        color: #7C7C7C;
        font-family: Heebo;
        letter-spacing: -0.13px;
        line-height: 24px;
        font-size: 1rem;
    }

    .combobox {
        overflow-y: hidden;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.09);    
        border-radius: 5px;

        hr {
            border: 0;
            height: 0.2px;
            background: #EDEDED;
            width: 92%;
            margin: 0 auto;
            margin-bottom: 2px;
        }
    }
`;

const Select = ({ name, label, options, setFieldValue, setFieldTouched }) => {
    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState(false);

    useEffect(() => {
        setFieldValue(name, value);
    }, [value]);

    function handleComboboxClick() {
        setOpen(!isOpen);
    }

    function handleListItemClick(value) {
        setValue(value);
        setOpen(false);
    }

    return (
        <InputHolder>
            <label htmlFor={name}>{label}</label>
            <div className="combobox">
                <Toggle
                    type="text"
                    onClick={() => setOpen(!isOpen)}
                    isOpen={isOpen}
                >
                    {value || 'איזו קופת חולים?'}
                </Toggle>
                {isOpen && (
                    <hr />
                )}
                <Options
                    isOpen={isOpen}
                >
                    {options.map((opt) => (
                        <li
                            key={opt.value}
                            value={opt.value}
                            onClick={() => handleListItemClick(opt.value)}
                        >
                            {opt.text}
                        </li>
                    ))}
                </Options>
            </div>
            <ErrorMessageContainer>
                <ErrorMessage name={name}>{msg => <ErrorMsg>{msg}</ErrorMsg>}</ErrorMessage>
            </ErrorMessageContainer>
        </InputHolder>
    );
}


export default Select;
