import React from 'react';
import styled from 'styled-components';

import pheonix_70_years_logo from '../assets/pheonix_70_years_logo.png';
import shamaim_logo from '../assets/shamaim_logo.png';
import teachers from '../assets/teachers.png';

const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  align-items: center;
  margin: 0 1rem;

  @media (max-width: 40rem) {
    justify-content: space-around;
    padding-bottom: 0.3rem;
  }

  .pheonix__logo {
    background: url(${pheonix_70_years_logo}) no-repeat;
    width: 9rem;
    height: 6rem;
    background-size: cover;

    @media (max-width: 40rem) {
      width: 8rem;
    }
  }

  .shamaim__logo {
    background: url(${shamaim_logo}) no-repeat;
    width: 6rem;
    height: 5rem;
    background-size: contain;

    @media (max-width: 40rem) {
      width: 4rem;
      height: 4rem; 
    }    
  }

  .teachers__logo {
    background: url(${teachers}) no-repeat;
    width: 8rem;
    height: 4rem;
    background-size: contain;

    @media (max-width: 40rem) {
      width: 4rem;
      height: 3rem; 
    }    
  }
`;

function Header() {
  return (
    <StyledHeader>
      <div className='pheonix__logo'></div>
      <div className='teachers__logo'></div>
      <div className='shamaim__logo'></div>
    </StyledHeader>
  );
}

export default Header;
