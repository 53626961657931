import React from 'react';
import styled from 'styled-components';
import {  ErrorMessage } from 'formik';
import ErrorMsg, { ErrorMessageContainer } from './ErrorMsg';

const StyledInput = styled.input`
    border: 0;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.09);
    background-color: #FFF;
    border-radius: 5px;
    min-height: 40px;
    height: 2.5rem;
    color: #7C7C7C !important;
    padding-right: 0.5rem;
    outline-color: #F5CB6E;
    outline-width: medium;
    margin: 0;
    -webkit-appearance: none;
    font-family: Heebo;

    @media (max-width: 40rem) {
        min-height: 55px;
        height: 3.43rem;
    }
`;

const InputHolder = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.width ? props.width : '250px'};
    min-height: 6rem;
    justify-content: space-around;
    position: relative;

    @media (max-width: 40rem) {
        width: initial;
    }

    label {
        color: #7C7C7C;
        font-family: Heebo;
        letter-spacing: -0.13px;
        line-height: 24px;
        font-size: 1rem;
    }
`;

const Input = ({ name, width, label, type = 'text', ...props }) => (
    <InputHolder width={width}>
        <label htmlFor={name}>{label}</label>
        <StyledInput name={name} type={type} {...props} />
        <ErrorMessageContainer>
            <ErrorMessage name={name}>{msg => <ErrorMsg>{msg}</ErrorMsg>}</ErrorMessage>
        </ErrorMessageContainer>
    </InputHolder>
);

export default Input;
