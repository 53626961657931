import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import ErrorMsg, { ErrorMessageContainer } from './ErrorMsg';

const InputHolder = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 4rem;
    justify-content: space-around;
    
    [type="radio"] {
        position: absolute;
        left: -9999px;
    }

    .styledRadioLabel  {
        cursor: pointer;
        height: 40px;
        /* width: 120px; */
        border-radius: 5px;
        background-color: #FFFFFF;
        color: #7C7C7C;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.09);
        -webkit-appearance: none;
        text-align: center;
        display: grid;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
    }
    
    [type="radio"]:focus + .styledRadioLabel {
        border: 2px solid #F5CB6E99;
    }
    
    [type="radio"]:checked + .styledRadioLabel {
        background-color: #80B2CD;
        color: #FFF;
    }

    label {
        color: #7C7C7C;
        font-family: Heebo;
        letter-spacing: -0.13px;
        line-height: 24px;
        font-size: 16px;
    }

    .radio__button {
    }

    .radio__buttons {
        width: 250px;
        display: grid;
        grid-template-columns: 49% 49%;
        grid-gap: 0.5rem;
        position: relative;


        @media (max-width: 40rem) {
            width: initial;
        }
    }
`;

const Radio = ({ label, options, name, values, children, ...props }) => (
    <InputHolder>
        <label>{label}</label>
        <div className="radio__buttons">
            {options.map((option, idx) => (
                <div className="radio__button" key={option}>
                    <input
                        id={`${name + '_' + option + '_' + idx}`}
                        checked={values == option}
                        name={name}
                        value={option}
                        type="radio" {...props}
                    />
                    <label className="styledRadioLabel" htmlFor={`${name + '_' + option + '_' + idx}`}>{option}</label>
                </div>
            ))}
        </div>
        <ErrorMessageContainer>
            <ErrorMessage name={name}>{msg => <ErrorMsg>{msg}</ErrorMsg>}</ErrorMessage>
        </ErrorMessageContainer>
    </InputHolder>
);

export default Radio;
