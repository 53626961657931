import React, { useContext } from 'react';
import styled from 'styled-components';

import AppContext from '../App';
import Button from '../components/Button';

export const CandidateWidgets = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;

    @media (max-width: 40rem) {
        margin: initial;
    }

    .candidate__widget {
        display: flex;
        position: relative;
        flex-direction: column;
        border-radius: 0.4rem;
        min-height: 6rem;
        background-color: #ffe8b670;
        padding: 1rem;
        font-family: Heebo;
        margin: 1rem;
        font-size: 1rem;
    }

    .candidate__widget--delete {
        position: absolute;
        left: 1rem;
        top: 0.2rem;
        cursor: pointer;
    }

    .candidate__widget--name {
        padding: 0;
        margin: 0.5rem;
    }

    .candidate__widget--content {
        display: flex;
        margin: 0.5rem;
        justify-content: space-between;

        @media (max-width: 40rem) {
            flex-direction: column;   

            & > div {
                margin-top: 0.5rem;
            }
        }

        & > div {
            display: flex;
            flex-direction: column;
            margin-left: 1.5rem;

            & label {
                color: #7C7C7C;
            }
        }
    }

    .candidate__widget--complete {
        background-color: #F5CB6E99;
    }
`;

export const CandidateWidgetFinished = (props) => {
    const { startFlowHandler, deleteCandidate, editCandidate } = props;
    const { firstName, lastName, idNumber, gender, birthDate } = props.candidate;

    return (
        <div className='candidate__widget candidate__widget--complete'>
            <span className='candidate__widget--delete' onClick={() => deleteCandidate(props.candidate)}>&times;</span>
            <h5 className='candidate__widget--name'>{firstName + ' ' + lastName}</h5>
            <div className='candidate__widget--content'>
                <div>
                    <small>מספר תעודת זהות</small>
                    <strong>{idNumber}</strong>
                </div>
                <div>
                    <small>תאריך לידה</small>
                    <strong>{birthDate}</strong>
                </div>
                <div>
                    <small>מין</small>
                    <strong>{gender}</strong>
                </div>
                <div>
                    <Button 
                        style={{
                            width: 'initial',
                        }}
                        onClick={() => editCandidate(props.candidate)}
                    > 
                        עריכה  
                    </Button>
                </div>
                <div>
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="StyleGuide" transform="translate(-199.000000, -2052.000000)" fill="#80B2CD" fillRule="nonzero">
                                <g id="UI-Elements" transform="translate(48.000000, 1310.000000)">
                                    <g id="CheckBox" transform="translate(2.000000, 732.000000)">
                                        <g id="checked" transform="translate(144.000000, 7.000000)">
                                            <path d="M5,12.27 C5,12.09 5.08846154,11.82 5.26538462,11.64 L6.50384615,10.38 C6.85769231,10.02 7.38846154,10.02 7.74230769,10.38 L7.83076923,10.47 L12.6961538,15.78 C12.8730769,15.96 13.1384615,15.96 13.3153846,15.78 L25.2576923,3.27 C25.6115385,2.91 26.1423077,2.91 26.4961538,3.27 L27.7346154,4.53 C28.0884615,4.89 28.0884615,5.43 27.7346154,5.79 L13.5807692,20.73 C13.4038462,20.91 13.2269231,21 12.9615385,21 C12.6961538,21 12.5192308,20.91 12.3423077,20.73 L5.44230769,13.17 C5.1474359,12.69 5,12.39 5,12.27 Z" id="Path"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export const CandidateWidget = (props) => {
    const { startFlowHandler, deleteCandidate } = props;
    const { firstName, lastName, idNumber, gender, birthDate } = props.candidate;

    return (
        <div className='candidate__widget'>
            {/* <span className='candidate__widget--delete' onClick={() => deleteCandidate(props.candidate)}>&times;</span> */}
            <h5 className='candidate__widget--name'>{firstName + ' ' + lastName}</h5>
            <div className='candidate__widget--content'>
                <div>
                    <small>מספר תעודת זהות</small>
                    <strong>{idNumber}</strong>
                </div>
                <div>
                    <small>תאריך לידה</small>
                    <strong>{birthDate}</strong>
                </div>
                <div>
                    <small>מין</small>
                    <strong>{gender}</strong>
                </div>
            </div>
            {!!startFlowHandler && (
                <div>
                    <Button style={{ justifySelf: 'flex-end', width: '100%' }} onClick={() => startFlowHandler(props.candidate)}>מעבר לשאלון הרפואי</Button>
                </div>
            )}
        </div>
    );
};
