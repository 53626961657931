import React, { useEffect, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';

import ErrorMsg, { ErrorMessageContainer } from './ErrorMsg';

const StyledDateInput = styled.div`
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 0.5rem; 

    .date__input {
        border: 0;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.09);
        background-color: #FFF;
        border-radius: 5px;
        height: 40px;
        outline-color: #F5CB6E;
        outline-width: medium;
        color: #7C7C7C !important;
        margin: 0;
        display: inline-block;
        text-align: center;
        font-size: 1rem;

        @media (max-width: 40rem) {
            height: 55px;
        }
    }

    .date__input--day {
        appearance: none;
    }

    .date__input--month {
        appearance: none;
    }

    .date__input--year {
        appearance: none;
    }    
`;

const InputHolder = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 4rem;
    justify-content: space-around;

    position: relative;
    width: ${props => props.width ? props.width : '250px'};

    @media (max-width: 40rem) {
        width: initial;
    }

    label {
        color: #7C7C7C;
        font-family: Heebo;
        letter-spacing: -0.13px;
        line-height: 24px;
        font-size: 16px;
    }
`;

function DateInput({ name, label, value, setFieldValue, setFieldTouched, isLoading }) {
    const [didMount, setDidMount] = useState(false);
    const [loadingChanged, setLoadingChanged] = useState(false);

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        setDay('');
        setMonth('');
        setYear('');
        setLoadingChanged(true);
    }, [isLoading])

    useEffect(() => {
        if (!didMount) return;

        if ((!day || !month || !year) ) {
            if(loadingChanged) {
                setLoadingChanged(false);
                return;
            }
            setFieldTouched(name);
        } else {
            setFieldDateValue();
        }
    }, [day, month, year]);

    function setFieldDateValue() {
        const date = `${day}-${month}-${year}`;

        return setFieldValue(name, date);
    }

    function handleChange(e) {
        switch (e.target.name) {
            case 'day':
                setDay(e.target.value);
                break;
            case 'month':
                setMonth(e.target.value);
                break;
            case 'year':
                setYear(e.target.value);
                break;
            default:
                break;
        }
    }

    return (
        <InputHolder>
            <label htmlFor={name}>{label}</label>
            <StyledDateInput>
                <input
                    className="date__input date__input--year"
                    placeholder="שנה"
                    onBlur={e => setFieldTouched(name)}
                    onChange={handleChange}
                    value={year}
                    name="year"
                />
                <input
                    className="date__input date__input--month"
                    placeholder="חודש"
                    onBlur={e => setFieldTouched(name)}
                    onChange={handleChange}
                    value={month}
                    name="month"
                />
                <input
                    className="date__input date__input--day"
                    placeholder="יום"
                    onBlur={e => setFieldTouched(name)}
                    onChange={handleChange}
                    value={day}
                    name="day"
                />
                <input
                    type="hidden"
                    name={name}
                    value={value}
                />
            </StyledDateInput>
            <ErrorMessageContainer>
                <ErrorMessage name={name}>{msg => <ErrorMsg>{msg}</ErrorMsg>}</ErrorMessage>
            </ErrorMessageContainer>
        </InputHolder>
    );
}

export default DateInput;
