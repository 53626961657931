import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ScaleLoader } from 'react-spinners';

import Input from './Input';

const UploadHolder = styled.div`
    border: 1px solid #7C7C7C11;
    padding: 1rem;

    input[type="file"] {
        opacity: 0;
        display: none;
    }

    label {
        border: 1px solid #80B2CD;
        border-radius: 5rem;
        padding: 0.35rem 0.7rem;
        color: #80B2CD;
        background: #FFF;
        font-family: Heebo;
        letter-spacing: -0.13px;
        line-height: 24px;
        font-size: 1rem;        
        cursor: pointer;
    }

    span {
        margin-right: 1rem;
        color: #7C7C7C;
        font-family: Heebo;
        letter-spacing: -0.13px;        
    }
`;

const InputWithUploadHolder = styled.div`
`;

function Upload({ questionId, uploadFileName, formikValues, setFieldValue }) {
    const [loading, setLoading] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState(undefined);

    const file = formikValues[uploadFileName];

    useEffect(() => {
        if(!file) {
            return;
        }

        setLoading(true);

        let reader = new window.FileReader();

        reader.onloadend = (event) => {
            setUploadedFileName(file.name);
            setLoading(false);
        }

        reader.readAsDataURL(file);
    }, [file]);

    return (
        <UploadHolder className="upload__file">
            <input 
                type="file" 
                name={uploadFileName} 
                id={uploadFileName} 
                onChange={(event) => {
                    console.log(event.currentTarget.files);
                    setFieldValue(uploadFileName, event.currentTarget.files[0]);
                }}
            />
            <label 
                htmlFor={uploadFileName}
            >
                {loading ? 'טעינה...' : 'במידה ויש מסמכים רלוונטים - אנא צרף'}
            </label>
            {uploadedFileName && (
                <span>{uploadedFileName}</span>
            )}
        </UploadHolder>
    );
}

function InputWithUpload({ uploadFileName, formikValues, setFieldValue, questionId, ...props }) {
    return (
        <InputWithUploadHolder>
            <Input {...props} />
            <Upload 
                uploadFileName={uploadFileName}
                questionId={questionId}
                setFieldValue={setFieldValue}
                formikValues={formikValues}
            />
        </InputWithUploadHolder>
    );
}

export default InputWithUpload;
