import React from 'react';
import styled from 'styled-components';

import Button from '../../components/Button';

const StyledPage = styled.div`
    margin: 0 auto;
    max-width: 60vw;

    @media (max-width: 40rem) {
        max-width: 90vw;
    }}
    
    h1 {
        font-size: 40px;
        color: #80B2CD;
        letter-spacing: -0.33px;
        font-family: Heebo;
        line-height: 59px;
        font-weight: 100;
        margin: 0;
        margin-top: 1rem;
    }

    h2 {
        font-family: Heebo;
        font-weight: bold;
        color: #80B2CD;
        letter-spacing: -0.21px;
        line-height: 37px;
        font-size: 25px;
    }

    p {
        font-family: Heebo;
        color: #7C7C7C;
        letter-spacing: -0.13px;
        font-size: 16px;
        margin: 0;
    }    
`;

const ThankYouPage = () => {
    return (
        <StyledPage>
            <h1>
                תודה!
            </h1>    
            <h2>
                קיבלנו את פנייתכם וניצור עמכם קשר בהקדם
            </h2>
            <p>
                מדובר בהצעה בלבד , הנתונים נשלחו לסוכנות הביטוח.
                אנו ניצור קשר טלפוני לקבלת אמצעי תשלום ואישור הנתונים לפני הפקת הפוליסה.
                אין לראות בתהליך זה – כאישור להפקת הפוליסה או לכל כיסוי ביטוחי.                
            </p>
            <br />
            <Button primary onClick={() => window.location = 'http://shamaims.co.il'}>
                חזרה לאתר
            </Button>
        </StyledPage>
    );
}

export default ThankYouPage;
