import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
    scrollToTop,
    calculateAge,
    resetPageScroll,
} from '../../helpers';

import {
    errorMessages,
    requiredMessages,
    step2Values,
    step3Values,
    step4Values,
    initialValues,
    insuranceHealthQuestionValidation,
} from '../validation';

import SignaturePage from './SignaturePage';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Radio from '../../components/Radio';
import RadioWithInput from '../../components/RadioWithInput';
import Checkbox from '../../components/Checkbox';
import Divider from '../../components/Divider';
import Select from '../../components/Select';
import FormButtonGroup from '../../components/FormButtonGroup';
import InputFields from '../../components/InputFields';

import { AppContext } from '../../App';

import { Medical_Questions_1, Medical_Questions_2 } from '../../text/questions';

const PageContainer = styled.div`
    margin: 0 auto;
    max-width: 60vw;
    padding-bottom: 1rem;
    display: grid;
    grid-gap: 0.5rem;

    @media (max-width: 40rem) {
        max-width: 90vw;
        /* display: block; */
    }}

    .another__candidate--container {
        display: flex;
        align-items: center;

        span {
            margin-right: 0.5rem;
            color: #7C7C7C;
            font-family: Heebo;
            letter-spacing: -0.13px;
            line-height: 24px;
            font-size: 0.7rem;
        }
    }

    span.next__button--info{
        justify-self: flex-end;
        margin-right: 0.5rem;
        color: #7C7C7C;
        font-family: Heebo;
        letter-spacing: -0.13px;
        line-height: 24px;
        font-size: 0.7rem;
    }

    .sigCanvas {
        border: 1px solid #7C7C7C;

        @media (max-width: 40rem) {
            max-width: 90%;
            margin: 0 auto;
        }
    }

    .page__title {
        display: flex;
        align-items: center;

        @media (max-width: 40rem) {
            & {
                display: block;
            }
        }

        span,
        h1 {
            font-size: 40px;
            color: #80B2CD;
            letter-spacing: -0.33px;
            font-family: Heebo;
            line-height: 59px;
            font-weight: 100;
            margin: 0;
            margin-top: 1rem;
        }

        span {
            margin-right: 1rem;

            &:before {
                content: "-";
                padding-left: 1rem;
            }

            @media (max-width: 40rem) {
                & {
                    margin-right: 0;
                }

                &:before {
                    content: "";
                    padding-left: 0;
                }                
            }            
        }
    }
    
    h1 {
        font-size: 40px;
        color: #80B2CD;
        letter-spacing: -0.33px;
        font-family: Heebo;
        line-height: 59px;
        font-weight: 100;
        margin: 0;
        margin-top: 1rem;
    }

    h2 {
        font-family: Heebo;
        font-weight: bold;
        color: #80B2CD;
        letter-spacing: -0.21px;
        line-height: 37px;
        font-size: 25px;
    }

    h4 {
        margin: 0;
        padding: 0;
        font-family: Heebo;
        color: #7C7C7C;
        letter-spacing: -0.13px;
    }

    h5 {
        font-family: Heebo;
        color: #7C7C7C;
        letter-spacing: -0.13px;
        margin: 0;
    }

    .flex__end {
        justify-content: flex-end;
        @media (max-width: 40rem) {
            justify-content: flex-start;
        }
    }

    .strong {
        font-weight: bold;
    }

    .license__agreement {
        max-height: 14rem;
        margin-top: 1rem;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.09);
        padding-right: 0.6rem;
        padding-top: 0.6rem;
        overflow-y: scroll;
        direction: ltr;

        /* width */
        ::-webkit-scrollbar {
        width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
        background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
        background: #80B2CD;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
        background: #80B2CD;
        }

        &--content {
            direction: rtl;
        }
    }

    p {
        font-family: Heebo;
        color: #7C7C7C;
        letter-spacing: -0.13px;
        font-size: 16px;
        margin: 0;
        /* overflow-y: auto; */
        padding: 0;
    }

    ol {
        margin: 0;

        /* padding-right: 1rem; */
        /* overflow-y: auto; */
    }

    ol li {
        font-family: Heebo;
        color: #7C7C7C;
        letter-spacing: -0.13px;
        font-size: 16px;
        margin-top: 4px; 
    }

    .heirs__details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;

        @media (max-width: 40rem) {
            display: block;
        }
    }

    form {
        display: grid;
        grid-template-rows: 1fr auto;
        grid-gap: 2rem;
        font-size: 16px;

        @media (max-width: 40rem) {
            display: grid;
        }
    }

    .is__a__smoker {
        display: grid;
        grid-template-columns: auto auto 1fr;
        align-items: flex-end;
        grid-gap: 1rem;

        @media (max-width: 40rem) {
            grid-template-columns: 1fr;
            
        }
    }
`;

const Step2Fields = ({ handleChange, handleBlur, values, currentCandidate, setFieldValue }) => {
    useEffect(() => {
        resetPageScroll();
    }, []);

    return (
        <>
            <Input
                questionId={Medical_Questions_1.height.questionId}
                name={Medical_Questions_1.height.name}
                label={Medical_Questions_1.height.label}
                value={values.height}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <Input
                questionId={Medical_Questions_1.weight.questionId}
                name={Medical_Questions_1.weight.name}
                label={Medical_Questions_1.weight.label}
                value={values.weight}
                onChange={handleChange}
                onBlur={handleBlur}
            />

            {calculateAge(currentCandidate.birthDate) < 16 && (
                <RadioWithInput 
                    questionId={Medical_Questions_1.isUnder16ConsultRegardingWeight.questionId}
                    name={Medical_Questions_1.isUnder16ConsultRegardingWeight.name}
                    label={Medical_Questions_1.isUnder16ConsultRegardingWeight.label}
                    labelDescription={Medical_Questions_1.isUnder16ConsultRegardingWeight.labelDescription}
                    options={Medical_Questions_1.isUnder16ConsultRegardingWeight.options}
                    values={values.isUnder16ConsultRegardingWeight}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    currentCandidate={currentCandidate}
                    formikValues={values}
                />
            )}

            {calculateAge(currentCandidate.birthDate) >= 16 && (
                <RadioWithInput
                    questionId={Medical_Questions_1.drasticWeightChangesLastFiveYears.questionId}
                    name={Medical_Questions_1.drasticWeightChangesLastFiveYears.name}
                    label={Medical_Questions_1.drasticWeightChangesLastFiveYears.label}
                    labelDescription={Medical_Questions_1.drasticWeightChangesLastFiveYears.labelDescription}
                    options={Medical_Questions_1.drasticWeightChangesLastFiveYears.options}
                    values={values.drasticWeightChangesLastFiveYears}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    formikValues={values}
                />
            )}

            <RadioWithInput
                questionId={Medical_Questions_1.beenThroughInnerProcedureOrAdvicedSo.questionId}
                name={Medical_Questions_1.beenThroughInnerProcedureOrAdvicedSo.name}
                label={Medical_Questions_1.beenThroughInnerProcedureOrAdvicedSo.label}
                labelDescription={Medical_Questions_1.beenThroughInnerProcedureOrAdvicedSo.labelDescription}
                options={Medical_Questions_1.beenThroughInnerProcedureOrAdvicedSo.options}
                values={values.beenThroughInnerProcedureOrAdvicedSo}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                formikValues={values}
            />

            <RadioWithInput
                questionId={Medical_Questions_1.hospitalizedLastFiveYears.questionId}
                name={Medical_Questions_1.hospitalizedLastFiveYears.name}
                label={Medical_Questions_1.hospitalizedLastFiveYears.label}
                labelDescription={Medical_Questions_1.hospitalizedLastFiveYears.labelDescription}
                options={Medical_Questions_1.hospitalizedLastFiveYears.options}
                values={values.hospitalizedLastFiveYears}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                formikValues={values}
            />

            <RadioWithInput
                questionId={Medical_Questions_1.beenThroughAnyKindOfSurgery.questionId}
                name={Medical_Questions_1.beenThroughAnyKindOfSurgery.name}
                label={Medical_Questions_1.beenThroughAnyKindOfSurgery.label}
                labelDescription={Medical_Questions_1.beenThroughAnyKindOfSurgery.labelDescription}
                options={Medical_Questions_1.beenThroughAnyKindOfSurgery.options}
                values={values.beenThroughAnyKindOfSurgery}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                formikValues={values}
            />

            <RadioWithInput
                questionId={Medical_Questions_1.isTakingDrugsRegularly.questionId}
                name={Medical_Questions_1.isTakingDrugsRegularly.name}
                label={Medical_Questions_1.isTakingDrugsRegularly.label}
                labelDescription={Medical_Questions_1.isTakingDrugsRegularly.labelDescription}
                options={Medical_Questions_1.isTakingDrugsRegularly.options}
                values={values.isTakingDrugsRegularly}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                formikValues={values}
            />

            <RadioWithInput
                questionId={Medical_Questions_1.hasDisabilityOrInProcess.questionId}
                name={Medical_Questions_1.hasDisabilityOrInProcess.name}
                label={Medical_Questions_1.hasDisabilityOrInProcess.label}
                labelDescription={Medical_Questions_1.hasDisabilityOrInProcess.labelDescription}
                options={Medical_Questions_1.hasDisabilityOrInProcess.options}
                values={values.hasDisabilityOrInProcess}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                formikValues={values}
            />

            <Radio
                questionId={Medical_Questions_1.isASmoker.questionId}
                name={Medical_Questions_1.isASmoker.name}
                label={Medical_Questions_1.isASmoker.label}
                options={Medical_Questions_1.isASmoker.options}
                values={values.isASmoker}
                onBlur={handleBlur}
                onChange={handleChange}
            />
            {
                values.isASmoker == 'כן' && (
                    <div className='is__a__smoker'>
                        <Input
                            questionId={Medical_Questions_1.isASmokerPerDay.questionId}
                            name={Medical_Questions_1.isASmokerPerDay.name}
                            label={Medical_Questions_1.isASmokerPerDay.label}
                            value={values.isASmokerPerDay}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            width={'100px'}
                        />
                        <Input
                            questionId={Medical_Questions_1.isASmokerYears.questionId}
                            name={Medical_Questions_1.isASmokerYears.name}
                            label={Medical_Questions_1.isASmokerYears.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isASmokerYears}
                            width={'100px'}
                        />
                        <Input
                            questionId={Medical_Questions_1.isASmokerDetails.questionId}
                            name={Medical_Questions_1.isASmokerDetails.name}
                            label={Medical_Questions_1.isASmokerDetails.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isASmokerDetails}
                            width={'400px'}
                        />
                    </div>
                )
            }
        </>
    )
};

const Step3Fields = ({ handleChange, handleBlur, values, currentCandidate, setFieldValue }) => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (<>
        <RadioWithInput
            questionId={Medical_Questions_2.hasHeartConditions.questionId}
            name={Medical_Questions_2.hasHeartConditions.name}
            label={Medical_Questions_2.hasHeartConditions.label}
            labelDescription={Medical_Questions_2.hasHeartConditions.labelDescription}
            options={Medical_Questions_2.hasHeartConditions.options}
            values={values.hasHeartConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasNervousSystemConditions.questionId}
            name={Medical_Questions_2.hasNervousSystemConditions.name}
            label={Medical_Questions_2.hasNervousSystemConditions.label}
            labelDescription={Medical_Questions_2.hasNervousSystemConditions.labelDescription}
            options={Medical_Questions_2.hasNervousSystemConditions.options}
            values={values.hasNervousSystemConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasDigestiveSystemConditions.questionId}
            name={Medical_Questions_2.hasDigestiveSystemConditions.name}
            label={Medical_Questions_2.hasDigestiveSystemConditions.label}
            labelDescription={Medical_Questions_2.hasDigestiveSystemConditions.labelDescription}
            options={Medical_Questions_2.hasDigestiveSystemConditions.options}
            values={values.hasDigestiveSystemConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasEndocrineSystemConditions.questionId}
            name={Medical_Questions_2.hasEndocrineSystemConditions.name}
            label={Medical_Questions_2.hasEndocrineSystemConditions.label}
            labelDescription={Medical_Questions_2.hasEndocrineSystemConditions.labelDescription}
            options={Medical_Questions_2.hasEndocrineSystemConditions.options}
            values={values.hasEndocrineSystemConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasEyesConditions.questionId}
            name={Medical_Questions_2.hasEyesConditions.name}
            label={Medical_Questions_2.hasEyesConditions.label}
            labelDescription={Medical_Questions_2.hasEyesConditions.labelDescription}
            options={Medical_Questions_2.hasEyesConditions.options}
            values={values.hasEyesConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasEarsNoseConditions.questionId}
            name={Medical_Questions_2.hasEarsNoseConditions.name}
            label={Medical_Questions_2.hasEarsNoseConditions.label}
            labelDescription={Medical_Questions_2.hasEarsNoseConditions.labelDescription}
            options={Medical_Questions_2.hasEarsNoseConditions.options}
            values={values.hasEarsNoseConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasSkeletOrBackConditions.questionId}
            name={Medical_Questions_2.hasSkeletOrBackConditions.name}
            label={Medical_Questions_2.hasSkeletOrBackConditions.label}
            labelDescription={Medical_Questions_2.hasSkeletOrBackConditions.labelDescription}
            options={Medical_Questions_2.hasSkeletOrBackConditions.options}
            values={values.hasSkeletOrBackConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasLungsOrCoughingConditions.questionId}
            name={Medical_Questions_2.hasLungsOrCoughingConditions.name}
            label={Medical_Questions_2.hasLungsOrCoughingConditions.label}
            labelDescription={Medical_Questions_2.hasLungsOrCoughingConditions.labelDescription}
            options={Medical_Questions_2.hasLungsOrCoughingConditions.options}
            values={values.hasLungsOrCoughingConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasKidneysConditions.questionId}
            name={Medical_Questions_2.hasKidneysConditions.name}
            label={Medical_Questions_2.hasKidneysConditions.label}
            labelDescription={Medical_Questions_2.hasKidneysConditions.labelDescription}
            options={Medical_Questions_2.hasKidneysConditions.options}
            values={values.hasKidneysConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasCancerConditions.questionId}
            name={Medical_Questions_2.hasCancerConditions.name}
            label={Medical_Questions_2.hasCancerConditions.label}
            labelDescription={Medical_Questions_2.hasCancerConditions.labelDescription}
            options={Medical_Questions_2.hasCancerConditions.options}
            values={values.hasCancerConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasBloodConditions.questionId}
            name={Medical_Questions_2.hasBloodConditions.name}
            label={Medical_Questions_2.hasBloodConditions.label}
            labelDescription={Medical_Questions_2.hasBloodConditions.labelDescription}
            options={Medical_Questions_2.hasBloodConditions.options}
            values={values.hasBloodConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasSkinConditions.questionId}
            name={Medical_Questions_2.hasSkinConditions.name}
            label={Medical_Questions_2.hasSkinConditions.label}
            labelDescription={Medical_Questions_2.hasSkinConditions.labelDescription}
            options={Medical_Questions_2.hasSkinConditions.options}
            values={values.hasSkinConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasAutoimmuneConditions.questionId}
            name={Medical_Questions_2.hasAutoimmuneConditions.name}
            label={Medical_Questions_2.hasAutoimmuneConditions.label}
            labelDescription={Medical_Questions_2.hasAutoimmuneConditions.labelDescription}
            options={Medical_Questions_2.hasAutoimmuneConditions.options}
            values={values.hasAutoimmuneConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasPsychConditions.questionId}
            name={Medical_Questions_2.hasPsychConditions.name}
            label={Medical_Questions_2.hasPsychConditions.label}
            labelDescription={Medical_Questions_2.hasPsychConditions.labelDescription}
            options={Medical_Questions_2.hasPsychConditions.options}
            values={values.hasPsychConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}
        />

        {currentCandidate.gender == 'זכר' && (
            <RadioWithInput
                questionId={Medical_Questions_2.hasInfertilityConditions.questionId}
                name={Medical_Questions_2.hasInfertilityConditions.name}
                label={Medical_Questions_2.hasInfertilityConditions.label}
                labelDescription={Medical_Questions_2.hasInfertilityConditions.labelDescription}
                options={Medical_Questions_2.hasInfertilityConditions.options}
                values={values.hasInfertilityConditions}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                currentCandidate={currentCandidate}
                formikValues={values}
            />
        )}

        {currentCandidate.gender == 'זכר' && calculateAge(currentCandidate.birthDate) < 16 && (
            <RadioWithInput
                questionId={Medical_Questions_2.hasUnder16MaleUndescendedTestisConditions.questionId}
                name={Medical_Questions_2.hasUnder16MaleUndescendedTestisConditions.name}
                label={Medical_Questions_2.hasUnder16MaleUndescendedTestisConditions.label}
                labelDescription={Medical_Questions_2.hasUnder16MaleUndescendedTestisConditions.labelDescription}
                options={Medical_Questions_2.hasUnder16MaleUndescendedTestisConditions.options}
                values={values.hasUnder16MaleUndescendedTestisConditions}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                currentCandidate={currentCandidate}
                formikValues={values}
            />
        )}


        {currentCandidate.gender == 'נקבה' && (
            <RadioWithInput
                questionId={Medical_Questions_2.hasFemaleSexConditions.questionId}
                name={Medical_Questions_2.hasFemaleSexConditions.name}
                label={Medical_Questions_2.hasFemaleSexConditions.label}
                labelDescription={Medical_Questions_2.hasFemaleSexConditions.labelDescription}
                options={Medical_Questions_2.hasFemaleSexConditions.options}
                values={values.hasFemaleSexConditions}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                currentCandidate={currentCandidate}
                formikValues={values}
            />
        )}

        {currentCandidate.gender == 'נקבה' && (
            <RadioWithInput
                questionId={Medical_Questions_2.isPregrant.questionId}
                name={Medical_Questions_2.isPregrant.name}
                label={Medical_Questions_2.isPregrant.label}
                labelDescription={Medical_Questions_2.isPregrant.labelDescription}
                options={Medical_Questions_2.isPregrant.options}
                values={values.isPregrant}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                currentCandidate={currentCandidate}
                formikValues={values}                
            />
        )}

        {currentCandidate.gender == 'נקבה' && calculateAge(currentCandidate.birthDate) < 16 && (
            <RadioWithInput
                questionId={Medical_Questions_2.hasUnder16FemalePeriodConditions.questionId}
                name={Medical_Questions_2.hasUnder16FemalePeriodConditions.name}
                label={Medical_Questions_2.hasUnder16FemalePeriodConditions.label}
                labelDescription={Medical_Questions_2.hasUnder16FemalePeriodConditions.labelDescription}
                options={Medical_Questions_2.hasUnder16FemalePeriodConditions.options}
                values={values.hasUnder16FemalePeriodConditions}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                currentCandidate={currentCandidate}
                formikValues={values}                  
            />
        )}

        <RadioWithInput
            questionId={Medical_Questions_2.hasHerniaConditions.questionId}
            name={Medical_Questions_2.hasHerniaConditions.name}
            label={Medical_Questions_2.hasHerniaConditions.label}
            labelDescription={Medical_Questions_2.hasHerniaConditions.labelDescription}
            options={Medical_Questions_2.hasHerniaConditions.options}
            values={values.hasHerniaConditions}
            onBlur={handleBlur}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}              
        />

        <RadioWithInput
            questionId={Medical_Questions_2.hasCloseFamilyConditions.questionId}
            name={Medical_Questions_2.hasCloseFamilyConditions.name}
            label={Medical_Questions_2.hasCloseFamilyConditions.label}
            labelDescription={Medical_Questions_2.hasCloseFamilyConditions.labelDescription}
            options={Medical_Questions_2.hasCloseFamilyConditions.options}
            values={values.hasCloseFamilyConditions}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            currentCandidate={currentCandidate}
            formikValues={values}              
        />

        {calculateAge(currentCandidate.birthDate) < 18 && (
            <>
                <RadioWithInput
                    questionId={Medical_Questions_2.isUnder18GrowthConditions.questionId}
                    name={Medical_Questions_2.isUnder18GrowthConditions.name}
                    label={Medical_Questions_2.isUnder18GrowthConditions.label}
                    labelDescription={Medical_Questions_2.isUnder18GrowthConditions.labelDescription}
                    options={Medical_Questions_2.isUnder18GrowthConditions.options}
                    values={values.isUnder18GrowthConditions}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    currentCandidate={currentCandidate}
                    formikValues={values}  
                />

                <RadioWithInput
                    questionId={Medical_Questions_2.isUnder18CongenitalConditions.questionId}
                    name={Medical_Questions_2.isUnder18CongenitalConditions.name}
                    label={Medical_Questions_2.isUnder18CongenitalConditions.label}
                    labelDescription={Medical_Questions_2.isUnder18CongenitalConditions.labelDescription}
                    options={Medical_Questions_2.isUnder18CongenitalConditions.options}
                    values={values.isUnder18CongenitalConditions}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    currentCandidate={currentCandidate}
                    formikValues={values}  
                />

                {currentCandidate.isUnder18CongenitalConditions == 'כן' && (
                    <small>
                        נא להוסיף דו"ח אבחנות וטיפולים עדניים מהרופא המטפל
                    </small>
                )}
            </>
        )}

        {calculateAge(currentCandidate.birthDate) < 3 && (
            <RadioWithInput
                questionId={Medical_Questions_2.isUnder3PrematureBirthConditions.questionId}
                name={Medical_Questions_2.isUnder3PrematureBirthConditions.name}
                label={Medical_Questions_2.isUnder3PrematureBirthConditions.label}
                labelDescription={Medical_Questions_2.isUnder3PrematureBirthConditions.labelDescription}
                options={Medical_Questions_2.isUnder3PrematureBirthConditions.options}
                values={values.isUnder3PrematureBirthConditions}
                onChange={handleChange}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                currentCandidate={currentCandidate}
                formikValues={values}  
            />
        )}
    </>)
}

const stepTitles = {
    2: 'האם אובחנה אחת המחלות / קיים אחד הליקויים והמומים / בוצעו בדיקות וטיפולים או נטילת תרופות / קיימת תשובה חיובית לאחת מהשאלות המפורטות מטה:',
    3: 'האם חלית או אובחנת או סבלת מאחת מהמחלות הבאות או אחד מהאירועים?',
}

const PageTitle = ({ currentCandidate }) => {
    const fullName = `${currentCandidate.firstName} ${currentCandidate.lastName}`;

    return (
        <div className="page__title">
            <h1>שאלון הצהרת בריאות</h1>
            <span>{fullName}</span>
        </div>
    )
}

export const StepsManager = () => {
    const {
        setLoading,
        candidates,
        addCandidate,
        step,
        setStep,
        currentCandidate,
        setCurrentCandidate,
        completedCandidates,
        setCompletedCandidates,
        updateCandidates,
    } = useContext(AppContext);

    const [afterSignatureButtonEnabled, setAfterSignatureButtonEnabled] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    function handleSubmit(values, functions) {
        console.log('submitting...');
    }

    const INITIAL = currentCandidate || initialValues;

    return (
        <PageContainer>
            <span
                style={{
                    fontSize: '1rem',
                    textDecoration: 'underline',
                    color: '#F5CB6E',
                    cursor: 'pointer',
                }}
                onClick={() => setStep(step - 1)}
            >
                &lt;&lt; חזרה
            </span>
            <PageTitle 
                currentCandidate={currentCandidate}
            />
            {stepTitles[step] && (
                <h2>
                    {stepTitles[step]}
                </h2>
            )}

            <Formik
                //initialValues={currentCandidate ? {...currentCandidate, ...initialValues} : initialValues}
                initialValues={INITIAL}
                validationSchema={insuranceHealthQuestionValidation}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    validateForm,
                    resetForm,
                    setFieldTouched,
                    setStatus,
                    setFieldValue,
                }) => (
                        <form onSubmit={handleSubmit}>
                            <InputFields style={{ gridTemplateColumns: '1fr', gridGap: '2rem' }}>
                                {step == 2 && (
                                    <Step2Fields
                                        values={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        currentCandidate={currentCandidate}
                                        setFieldValue={setFieldValue}
                                    />
                                )}

                                {step == 3 && (
                                    <Step3Fields
                                        values={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        currentCandidate={currentCandidate}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </InputFields>
                            {step == 4 && (
                                <SignaturePage
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    currentCandidate={currentCandidate}
                                    setStep={setStep}
                                    step={step}
                                    setFieldValue={setFieldValue}
                                    completedCandidates={completedCandidates}
                                    setAfterSignatureButtonEnabled={setAfterSignatureButtonEnabled}
                                />
                            )}
                            <Divider />
                            <FormButtonGroup
                                className="flex__end"
                            >
                                <Button
                                    primary
                                    disabled={step == 4 && !afterSignatureButtonEnabled}
                                    isDisabled={step == 4 && !afterSignatureButtonEnabled}
                                    onClick={() => validateForm().then(errors => {
                                        const errorFields = Object.keys(errors);

                                        let currentStepValues;
                                        switch (step) {
                                            case 2:
                                                currentStepValues = step2Values;
                                                break;
                                            case 3:
                                                currentStepValues = step3Values;
                                                break;
                                            case 4:
                                                currentStepValues = step4Values;
                                                break;
                                            default:
                                                break;
                                        }

                                        if (currentStepValues.some(value => errors[value])) {
                                            errorFields.forEach(key => {
                                                if (currentStepValues.includes(key)) {
                                                    setFieldTouched(key);
                                                }
                                            });
                                        } else {
                                            setLoading(true);
                                            setTimeout(() => {
                                                if (step == 4) {
                                                    setCompletedCandidates({
                                                        ...completedCandidates,
                                                        [currentCandidate.idNumber]: {
                                                            ...currentCandidate,
                                                            ...values,
                                                        }
                                                    })
                                                }

                                                updateCandidates({
                                                    ...currentCandidate,
                                                    ...values,
                                                });

                                                setCurrentCandidate({
                                                    ...currentCandidate,
                                                    ...values,
                                                });
                                                setStep(step + 1);
                                                setLoading(false);
                                            }, 1200);
                                        }
                                    })}>
                                    {step == 4 ? 'סיום' : 'המשך'}
                                </Button>
                            </FormButtonGroup>
                        </form>
                    )}
            </Formik>
        </PageContainer>
    );
}
